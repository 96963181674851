import React from "react";
import {Box, Button, Dialog, DialogContent, MenuItem, SvgIcon, TextField, Typography} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import {useDialog} from "../../../hooks/use-dialog";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import useOmniaApi from "../../../hooks/use-omnia-api";
import {useNotifications} from "../../../hooks/use-notifications";
import {useSecurityCheck} from "../../../hooks/use-security-check";

function CreateUserRequest(){

    const dialog = useDialog();
    const { post } = useOmniaApi();
    const { notify } = useNotifications();
    const { hasRights } = useSecurityCheck();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            kind: 'bug',
            description: ''
        },
        onSubmit: (values, helpers) => {
            helpers.setSubmitting(true);
            post('omnia_requests', values).then(() => {
                helpers.resetForm();
                dialog.handleClose();
                notify(t("common.thank_you"), "success");
            }).finally(() => {
                helpers.setSubmitting(false);
            });

        }
    })

    const labels = {
        feature: t("common.feature_request"),
        change: t("common.change_request"),
        bug: t("common.bug_report"),
        other: t("common.other")
    }

    const placeholder = {
        feature: t("common.feature_request_placeholder"),
        change: t("common.change_request_placeholder"),
        bug: t("common.bug_report_placeholder"),
        other: t("common.other_placeholder")
    }

    const openRequest = () => {
        dialog.handleOpen(null);
    }

    return (
        <>
            <Dialog
                open={dialog.open}
                onClose={dialog.handleClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogContent>
                    <Typography variant="h4">
                        {t("common.create_request")}
                    </Typography>
                    <Box mt={4}>
                        <TextField
                            select
                            fullWidth
                            label={t("attributes.kind")}
                            name="kind"
                            onChange={formik.handleChange}
                            value={formik.values.kind}
                        >
                            {hasRights('on_feature_request') && (
                                <MenuItem value="feature">{labels['feature']}</MenuItem>
                            )}
                            {hasRights('on_change_request') && (
                                <MenuItem value="change">{labels['change']}</MenuItem>
                            )}
                            {hasRights('on_bug_report') && (
                                <MenuItem value="bug">{labels['bug']}</MenuItem>
                            )}
                            {hasRights('on_other_request') && (
                                <MenuItem value="other">{labels['other']}</MenuItem>
                            )}
                        </TextField>
                        <TextField
                            fullWidth
                            autoFocus={true}
                            label={labels[formik.values.kind]}
                            placeholder={placeholder[formik.values.kind]}
                            name="description"
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            multiline
                            rows={4}
                            sx={{mt: 2}}
                        />
                    </Box>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={formik.isSubmitting}
                            onClick={formik.handleSubmit}
                        >
                            {t("common.send")}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Box sx={{p: 2}}>
                <Typography variant="subtitle1">
                    {t("common.support")}
                </Typography>
                <Typography
                    color="neutral.400"
                    sx={{mb: 2}}
                    variant="body2"
                >
                    {t("common.how_can_we_help")}
                </Typography>
                <Button
                    fullWidth
                    onClick={openRequest}
                    startIcon={(
                        <SvgIcon fontSize="small">
                            <AddCircle />
                        </SvgIcon>
                    )}
                    variant="contained"
                >
                    {t("common.create_request")}
                </Button>
            </Box>
        </>
    )
}

export default CreateUserRequest;