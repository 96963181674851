import PropTypes from 'prop-types';
import DotsHorizontalIcon from '@untitled-ui/icons-react/build/esm/DotsHorizontal';
import {
    Avatar,
    AvatarGroup,
    Box, DialogActions, Divider, Fade,
    Grid,
    IconButton, Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    SvgIcon,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {useCreateSrc} from "../../../../../hooks/use-create-src";
import React, {memo, useEffect, useRef, useState} from "react";
import {useTheme} from "@mui/system";
import {useIsMobile} from "../../../../../hooks/use-is-mobile";
import {useNavigate} from "react-router";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useNotifications} from "../../../../../hooks/use-notifications";
import useApi from "../../../../../hooks/use-api";
import {addThread, muteThread, removeThread, unmuteThread} from "../../../../../store/actions/messages-actions";
import UserOnlineDot from "../../UserOnlineDot";
import {
    Bell as BellOnIcon,
    BellOff as BellOffIcon,
    Trash as TrashIcon,
    Users as GroupChatIcon
} from "react-feather";
import {Link as RouterLink} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {Formik, useFormik} from "formik";
import * as Yup from "yup";
import SmartSelector from "../../../../elements/smart-selector";
import {useTranslation} from "react-i18next";
import SaveButton from "../../../../elements/save-button";
import {APP_SETTING} from "../../../../../../setup";
import isEqual from "lodash/isEqual";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";

const getDisplayName = (recipients, thread) => {

    if (thread.is_group_chat)
        return thread.title;

    return recipients
        .map((participant) => participant.name)
        .join(', ');
};

function transformUsersValues(users) {
    let transformedUsers = [];
    // iterate through all users
    for (let i = 0; i < users.length; i++) {
        if (typeof (users[i]) == "object") {
            transformedUsers.push(users[i].id);
        } else {
            transformedUsers.push(users[i]);
        }
    }
    return transformedUsers;
}

function ChatThreadToolbar(props){
    const {participants = [], thread, toggleSidebar, ...other} = props;
    const user = useSelector(state => state.account.user);
    const getSrc = useCreateSrc();
    const {t} = useTranslation();
    const recipients = participants.filter((participant) => participant.id !== user.id)
    const displayName = getDisplayName(recipients, thread);
    const moreRef = useRef(null);
    const dispatch = useDispatch();
    const [openMenu, setOpenMenu] = useState(false);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const theme = useTheme();
    const {isMobile} = useIsMobile();
    const navigate = useNavigate();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const {notify} = useNotifications();
    const {del, post, put} = useApi();

    const handleMenuOpen = () => {
        setOpenMenu(true);
    };

    const handleMenuClose = () => {
        setOpenMenu(false);
    };

    const handleDialogOpen = () => {
        setOpen(true);
        handleMenuClose();
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        if (window.confirm("Bist du sicher, dass du den " + ((thread.is_group_chat) ? "Gruppenchat verlassen" : "Chat löschen") + " willst?")) {
            del('threads', thread.id)
                .then(() => {
                    dispatch(removeThread(thread));
                    navigate('/groon/chat');
                })
        }
    }

    const handleMuteNotifications = () => {
        dispatch(muteThread(thread));
        post('threads/' + thread.id + '/mute')
            .catch(() => {
                dispatch(unmuteThread(thread));
            })
    }

    const handleUnmuteNotifications = () => {
        dispatch(unmuteThread(thread));
        post('threads/' + thread.id + '/unmute')
            .catch(() => {
                dispatch(muteThread(thread));
            })
    }

    const settingsFormik = useFormik({
        initialValues: thread,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().required('Wähle einen Namen')
        }),
        onSubmit: (values, {setSubmitting}) => {
            setSubmitting(true);
            put('threads', {
                id: values.id,
                title: values.title,
                users: transformUsersValues(values.users)
            })
                .then(updatedThread => {
                    dispatch(addThread(updatedThread));
                    notify("Gespeichert", "success")
                    handleDialogClose();
                })
                .finally(() => {
                    setSubmitting(false);
                })
        }
    })

    useEffect(() => {
        // setShow(false);
        setTimeout(() => {
            setShow(true);
        }, 100);
    }, [thread, participants?.length]);

    if(!show)
        return false;

    return (
        <>
            <Fade in={true} timeout={APP_SETTING?.transitionDuration || 500}>
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{
                        flexShrink: 0,
                        minHeight: 64,
                        px: 2,
                        py: 1
                    }}
                    {...other}
                >
                    <IconButton onClick={toggleSidebar}>
                        <SvgIcon>
                            <Menu01Icon />
                        </SvgIcon>
                    </IconButton>
                    <Stack
                        alignItems="center"
                        direction="column"
                        justifyContent="center"
                        spacing={0}
                    >
                        <AvatarGroup
                            spacing="small"
                            max={isMobile ? 3 : 5}
                            total={recipients.length}
                            sx={{
                                '& .MuiAvatar-root': {
                                    height: 30,
                                    width: 30,

                                }
                            }}
                        >
                            {recipients.map((recipient) => (
                                <Avatar
                                    key={recipient.id}
                                    src={getSrc(recipient.avatar) || undefined}
                                />
                            ))}
                        </AvatarGroup>
                        <div>
                            {thread.is_group_chat ? (
                                <Typography variant="subtitle2">
                                    {displayName}
                                </Typography>

                            ) : (
                                <Link
                                    to={'/groon/profile/' + recipients?.[0]?.id}
                                    underline="hover"
                                    sx={{color: theme.palette.text.primary}}
                                    variant="subtitle2"
                                    component={RouterLink}
                                >
                                    {displayName || 'Unbekannt'}
                                </Link>
                            )}
                            {/*{(!thread.is_group_chat && recipients.length > 0) && (*/}
                            {/*    <UserOnlineDot*/}
                            {/*        userId={recipients[0].id}*/}
                            {/*        withText={true}*/}
                            {/*    />*/}
                            {/*)}*/}
                        </div>
                    </Stack>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                    >
                        <Tooltip title="More options">
                            <IconButton
                                onClick={handleMenuOpen}
                                ref={moreRef}
                                size="large"
                            >
                                <SvgIcon>
                                    <DotsHorizontalIcon/>
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
            </Fade>
            <Menu
                anchorEl={moreRef.current}
                keepMounted
                elevation={1}
                onClose={handleMenuClose}
                open={openMenu}
            >
                {(thread.is_group_chat) ? (
                    <MenuItem onClick={handleDialogOpen}>
                        <ListItemIcon>
                            <SvgIcon fontSize="small">
                                <GroupChatIcon/>
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText primary="Einstellungen"/>
                    </MenuItem>
                ) : null}
                {(thread.muted) ? (
                    <MenuItem onClick={handleUnmuteNotifications}>
                        <ListItemIcon>
                            <SvgIcon fontSize="small">
                                <BellOnIcon/>
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText primary="Einschalten"/>
                    </MenuItem>
                ) : (
                    <MenuItem onClick={handleMuteNotifications}>
                        <ListItemIcon>
                            <SvgIcon fontSize="small">
                                <BellOffIcon/>
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText primary="Ausschalten"/>
                    </MenuItem>
                )}
                <Divider />
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        <SvgIcon color="error" fontSize="small">
                            <TrashIcon/>
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary="Chat löschen" primaryTypographyProps={{color: 'error'}}/>
                </MenuItem>
            </Menu>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                fullWidth
                maxWidth="sm"
                onClose={handleDialogClose}
            >
                <DialogContent>
                    <Typography variant="h4" mb={4}>
                        {t('Channel Settings')}
                    </Typography>
                    <Grid container>
                        <Grid item sm={12} xs={12}>
                            <form onSubmit={settingsFormik.handleSubmit}>
                                <Box mt={2}>
                                    <TextField
                                        error={Boolean(settingsFormik.touched.title && settingsFormik.errors.title)}
                                        fullWidth
                                        helperText={settingsFormik.touched.title && settingsFormik.errors.title}
                                        label="Name"
                                        name="title"
                                        onBlur={settingsFormik.handleBlur}
                                        onChange={settingsFormik.handleChange}
                                        value={settingsFormik.values.title}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <SmartSelector
                                        label="Teilnehmer"
                                        endpoint='users'
                                        // options={users.filter(u => u.id !== user.id)}
                                        name="users"
                                        handleChange={settingsFormik.handleChange}
                                        values={transformUsersValues(settingsFormik.values.users)}
                                    />
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SaveButton
                        loading={settingsFormik.isSubmitting}
                        onClick={settingsFormik.handleSubmit}
                    />
                </DialogActions>
            </Dialog>
        </>
    )
}

ChatThreadToolbar.propTypes = {
    participants: PropTypes.array,
    thread: PropTypes.object,
    toggleSidebar: PropTypes.func
};

export default memo(ChatThreadToolbar, (prevProps, nextProps) => {
    const participantsEqual = isEqual(prevProps.participants, nextProps.participants);
    const threadTitleEqual = prevProps.thread?.title === nextProps.thread?.title;
    const threadIsGroupChatEqual = prevProps.thread?.is_group_chat === nextProps.thread?.is_group_chat;
    const threadMutedEqual = prevProps.thread?.muted === nextProps.thread?.muted;
    const threadIdEqual = prevProps.thread?.id === nextProps.thread?.id;

    return participantsEqual && threadTitleEqual && threadIsGroupChatEqual && threadMutedEqual && threadIdEqual;
});