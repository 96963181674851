import PropTypes from 'prop-types';
import SunIcon from '@untitled-ui/icons-react/build/esm/Sun';
import Moon01Icon from '@untitled-ui/icons-react/build/esm/Moon01';
import {Chip, Stack, SvgIcon, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";


export const OptionsColorScheme = (props) => {
    const {onChange, value} = props;
    const {t} = useTranslation();

    const options = [
        {
            label: t('common.on_settings.light'),
            value: 'light',
            icon: (
                <SvgIcon fontSize="small">
                    <SunIcon/>
                </SvgIcon>
            )
        },
        {
            label: t('common.on_settings.dark'),
            value: 'dark',
            icon: (
                <SvgIcon fontSize="small">
                    <Moon01Icon/>
                </SvgIcon>
            )
        }
    ];

    return (
        <Stack spacing={1}>
            <Typography
                color="text.secondary"
                variant="overline"
            >
                {t("common.on_settings.color_scheme")}
            </Typography>
            <Stack
                alignItems="center"
                direction="row"
                flexWrap="wrap"
                gap={2}
            >
                {options.map((option) => (
                    <Chip
                        icon={option.icon}
                        key={option.value}
                        label={option.label}
                        onClick={() => onChange?.(option.value)}
                        sx={{
                            borderColor: 'transparent',
                            borderRadius: 1.5,
                            borderStyle: 'solid',
                            borderWidth: 2,
                            ...(option.value === value && {
                                borderColor: 'primary.main'
                            })
                        }}
                    />
                ))}
            </Stack>
        </Stack>
    );
};

OptionsColorScheme.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOf(['light', 'dark'])
};
