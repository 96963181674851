import {useCallback, useEffect, useRef, useState} from 'react';
import { APP_SETTING } from "src/setup";
import {useDispatch, useSelector} from "react-redux";
import {useNotifications} from "./use-notifications";
import useOmniaApi from "./use-omnia-api";
import authService from "../services/auth-service";
import PropTypes from "prop-types";
import {useAppMode} from "./use-app-mode";
import {setWirelessReconnect} from "../store/actions/account-actions";
import {useNavigate} from "react-router";

const reloadWsCodes = [1006, 1011];

const useSocket = (url, onMessageCallback, isRequiredAuth = true) => {
    const wsRef = useRef(null);
    const accountStore = useSelector(state => state.account);
    const serviceStore = useSelector(state => state.service);
    const user = accountStore?.user || null;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const serviceUser = serviceStore?.user || null;
    const { post } = useOmniaApi({autoError: false});
    const [isInitial, setIsInitial] = useState(true);
    const [isConnected, setIsConnected] = useState(false);
    const { isScreenVisible, screenIsVisible } = useNotifications();
    const [retryInterval, setRetryInterval] = useState(1000);
    const intervalRef = useRef();
    const {isService} = useAppMode();
    const authRequiredRef = useRef();
    const userRef = useRef();
    const serviceUserRef = useRef();
    const isServiceRef = useRef();
    const timeoutRef = useRef();
    authRequiredRef.current = isRequiredAuth;
    serviceUserRef.current = serviceUser;
    intervalRef.current = retryInterval;
    isServiceRef.current = isService;
    userRef.current = user;

    const logSocketStatus = useCallback((mode, message) => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const currentHour = `${hours}:${minutes}:${seconds}`;
        if(typeof message === 'undefined'){
            console.log(currentHour + ' | WS ' + mode + ': ', url);
        } else {
            console.log(currentHour + ' | WS ' + mode + ' (' + message + '): ', url);
        }
    }, [url]);

    const scheduleReconnecting = (seconds, reason) => {
        const milliseconds = seconds * 1000;
        const reconnectTimestamp = (new Date().valueOf()) + milliseconds;

        dispatch(setWirelessReconnect(reconnectTimestamp))
        logSocketStatus('re-connecting', reason);

        timeoutRef.current = setTimeout(() => {
            reconnectIfAuthenticated();
        }, milliseconds);
    }

    const reconnectIfAuthenticated = useCallback(() => {

        // Clear existing timers
        timeoutRef.current && clearTimeout(timeoutRef.current);

        // Check if this socket required authentication
        if(isRequiredAuth){
            const token = isServiceRef.current ? localStorage.getItem('serviceToken') : authService.getAccessToken();
            if(isServiceRef.current){
                logSocketStatus('re-connecting', 'service token check');
            } else {
                logSocketStatus('re-connecting', 'groon token check');
            }
            post(isServiceRef.current ? 'services/login' : 'me/login', {token: token}).then(() => {
                connectToWebSocket();
            }).catch((errors) => {

                // Check if we have a network error (e.g. not connected to network)
                if(errors[0]?.status === 999){
                    scheduleReconnecting(30, 'no network');
                }

                // Check if we do not have a 403 error (e.g. not allows)
                else if(errors[0]?.status !== 403){
                    scheduleReconnecting(10, 'http ' + errors[0]?.status + '');
                }

                else {
                    logSocketStatus('re-connecting', 'stopped, logged out');
                    dispatch(setWirelessReconnect(null));

                    if(isServiceRef.current){
                        const serviceId = localStorage.getItem('serviceId');
                        if(serviceId){
                            navigate('/on/login/' + serviceId);
                        } else {
                            navigate('/');
                        }
                    } else {
                        navigate('/login')
                    }
                }

            })
        }

        // If no authentication is needed, reconnect directly after 5s
        else {
            timeoutRef.current = setTimeout(() => {
                connectToWebSocket();
            }, 5000);
        }

    }, [isRequiredAuth]);

    const createUrl = useCallback((url) => {

        let domain = APP_SETTING.domain;
        if(APP_SETTING.domain === 'localhost:3000')
            domain = 'localhost:8000';

        // Initiate a response variable
        let response = APP_SETTING.socket_protocol + "://" + domain + '/api';

        // Check for correct slashes etc.
        if(url.substr(0, 1) !== "/")
            url = "/" + url
        if(url.substr(url.length - 1, url.length) === "/"){
            url = url.substr(0, url.length - 1);
        }

        // Append the URL
        response += url;

        // Append the token if exists
        const token = isServiceRef.current ? localStorage.getItem('serviceToken') : authService.getAccessToken()
        if(token){
            response += '?token=' + token;
        }

        // Return the string
        return response;
    }, [])

    const sendMessage = useCallback((message) => {
        if (wsRef.current && wsRef.current.readyState === 1) {
            wsRef.current.send(JSON.stringify(message));
        }
    }, []);

    const connectToWebSocket = useCallback(() => {

        if(!url) return;

        const userAvailable = isServiceRef.current ? serviceUserRef.current : userRef.current;

        if (isRequiredAuth && !userAvailable) return;

        if (wsRef.current && (wsRef.current.readyState === WebSocket.OPEN || wsRef.current.readyState === WebSocket.CONNECTING)) {
            return;
        }

        wsRef.current = new WebSocket(createUrl(url));

        wsRef.current.onopen = () => {
            logSocketStatus('opened')
            setIsConnected(true);
            setIsInitial(false);

            // Reset interval
            setRetryInterval(1000);
        };

        wsRef.current.onclose = (event) => {
            logSocketStatus('closed', 'ws ' + event?.code);
            setIsConnected(false);

            // Check if the close was not intentional (code 1000 is a normal close)
            if (reloadWsCodes.includes(event.code)) {
                // Try to reconnect
                reconnectIfAuthenticated();
            }
        };

        wsRef.current.onmessage = (event) => {
            const message = JSON.parse(event.data);

            // check for availability test
            if( message && ("type" in message) && (message['type'] === 'availability_test') ){
                // respond to availability test
                sendMessage({
                    systemMessage: 'oa7sgdp56sdf7zt',
                    available: true,
                    visible: isScreenVisible()
                });
            } else {
                if(onMessageCallback)
                    onMessageCallback(message);
            }

        };

        wsRef.current.onerror = (event) => {
            logSocketStatus('error');
            console.log(event);
        }

    }, [createUrl, url, isRequiredAuth]);

    useEffect(() => {

        connectToWebSocket();

        return () => {
            if (wsRef.current) {
                wsRef.current.close(4200, "WS closed gracefully: ", url);
            }
            timeoutRef.current && clearTimeout(timeoutRef.current);
        };
    }, [user, url, isRequiredAuth]);

    useEffect(() => {

        // Attempt to reconnect as soon as the screen becomes visible
        if(!isConnected && screenIsVisible && !isInitial){
            dispatch(setWirelessReconnect(0));
            reconnectIfAuthenticated();
        }

        sendMessage({
            systemMessage: 'u65azfdjtku7',
            visible: screenIsVisible
        });
    }, [screenIsVisible, isInitial]);

    // FIXME: Remove this in the near future when it is not used anymore
    const socketOnline = isConnected;

    return { sendMessage, isConnected, socketOnline };
}

useSocket.propTypes = {
    url: PropTypes.string.isRequired,
    onMessageCallback: PropTypes.func.isRequired,
    isRequiredAuth: PropTypes.bool,
    isService: PropTypes.bool
};

export default useSocket;