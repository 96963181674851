// missingTranslationMiddleware.js
import {updateMissingTranslation} from "../actions/cms-frontend-actions";

const missingTranslationMiddleware = (store) => (next) => (action) => {
    if (action.type === 'TRANSLATION_LOOKUP') {

        const state = store.getState();
        const { payload: phrase } = action;
        const { languages, currentLanguageCode } = state.cmsFrontend;

        if (
            !languages[currentLanguageCode] ||
            !languages[currentLanguageCode][phrase]
        ) {
            store.dispatch(updateMissingTranslation(phrase));
        }
    }

    return next(action);
};

export default missingTranslationMiddleware;