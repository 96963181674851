import React from "react";
import {useDispatch} from "react-redux";
import {logRenderingError} from "../../store/actions/client-actions";
import {ErrorBoundary} from "react-error-boundary";
import ErrorPage from 'src/omnia/views/pages/500';

function ErrorHandler({children}){

    const dispatch = useDispatch();

    const handleError = (error) => {
        dispatch(logRenderingError(error.message, error.stack, "React Render"));
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorPage} onError={handleError}>
            {children}
        </ErrorBoundary>
    )
}

export default ErrorHandler;