import PropTypes from 'prop-types';
import {
    Box,
    List,
    Stack,
    Popover,
    Typography,
    Avatar,
} from '@mui/material';
import React from "react";
import { useNavigate } from "react-router";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import UserOnlineDot from "../../../modules/core/UserOnlineDot";

export const ContactsPopover = (props) => {

    const { t } = useTranslation();
    const {anchorEl, onClose, open = false, ...other} = props;
    const navigate = useNavigate();
    const user = useSelector((state) => state.account.user);
    const users = useSelector((state) => state.users.users);
    const usersInitialized = useSelector((state) => state.users.initialized);

    const openUser = (user) => {
        navigate('/groon/profile/' + user?.id);
        onClose();
    }

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom'
            }}
            disableScrollLock
            onClose={onClose}
            open={open}
            slotProps={{
                paper: {
                    sx: {
                        width: 275,
                        p: 1
                    }
                }
            }}
            {...other}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{p: 1}}
            >
                <Typography
                    color="inherit"
                    variant="h6"
                >
                    {t("core.contacts")}
                </Typography>
            </Stack>
            <Box sx={{p: 0, mt: 1}}>
                {users.filter(u => u.id !== user.id).length > 0 ? (
                    <List sx={{overscroll: 'auto', maxHeight: 350, p: 0, mb: 1}}>
                        {users.filter(u => u.id !== user.id).sort((a, b) => a.interactions > b.interactions ? -1 : 1).map((user, i) => (
                            <Stack
                                key={'contact-' + i}
                                component="li"
                                direction="row"
                                alignItems="center"
                                onClick={() => openUser(user)}
                                spacing={2}
                                sx={{
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                    px: 1,
                                    py: 1,
                                    my: 0.5,
                                    '&:hover': {
                                        backgroundColor: 'action.hover'
                                    },
                                }}
                            >
                                <Avatar
                                    src={user?.avatar?.view || null}
                                    sx={{
                                        height: 35,
                                        width: 35
                                    }}
                                />
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        overflow: 'hidden'
                                    }}
                                >
                                    <Typography
                                        noWrap
                                        variant="subtitle1"
                                    >
                                        {user?.name}
                                    </Typography>
                                    <Typography
                                        noWrap
                                        variant="subtitle2"
                                        color="text.secondary"
                                    >
                                        {user?.position}
                                    </Typography>
                                </Box>
                                <UserOnlineDot
                                    userId={user?.id}
                                />
                            </Stack>
                        ))}
                    </List>
                ) : (
                    <div style={{height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography variant="caption">
                            {usersInitialized ? t("core.no_contacts") : t('common.loading')}
                        </Typography>
                    </div>
                )}
            </Box>
        </Popover>
    );
};

ContactsPopover.propTypes = {
    anchorEl: PropTypes.any,
    contacts: PropTypes.array,
    onClose: PropTypes.func,
    open: PropTypes.bool
};
