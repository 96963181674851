import PropTypes from 'prop-types';
import {
    Box,
    IconButton,
    List,
    Stack,
    Popover,
    SvgIcon,
    Tooltip,
    Typography, CircularProgress
} from '@mui/material';
import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router";
import {useSelector} from "react-redux";
import MessagesIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import produce from "immer";
import { ChatThreadItem as ThreadItem } from "../../../modules/core/connect/chat/chat-thread-item";
import {useTranslation} from "react-i18next";

export const ChatPopover = (props) => {

    const { t } = useTranslation();
    const {anchorEl, onClose, open = false, ...other} = props;
    const navigate = useNavigate();
    const threads = useSelector((state) => state.messages.threads);
    const threadsLoaded = useSelector((state) => state.messages.threadsLoaded);
    const [sortedThreads, setSortedThreads] = useState([]);

    const openChat = () => {
        navigate('/groon/chat');
        onClose();
    }

    useEffect(() => {
        setSortedThreads(produce(threads, draft => {
            draft.sort((a, b) => {
                if ((typeof (a.last_message) !== "undefined") && (typeof (b.last_message) !== "undefined")) {
                    if ((a.last_message !== null) && (b.last_message !== null)) {
                        a = (new Date(a.last_message.created_at)).getTime();
                        b = (new Date(b.last_message.created_at)).getTime();
                        return ((a > b)) ? -1 : 1;
                    }
                }
                return 1;
            });
        }));
    }, [threads]);

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom'
            }}
            disableScrollLock
            onClose={onClose}
            open={open}
            slotProps={{
                paper: {
                    sx: {
                        width: 300,
                        p: 1
                    }
                }
            }}
            {...other}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{p: 1}}
            >
                <Typography
                    color="inherit"
                    variant="h6"
                >
                    {t("common.messages")}
                </Typography>
                <Tooltip title={t("common.to_my_messages")} placement="left">
                    <IconButton
                        onClick={openChat}
                        size="small"
                        color="inherit"
                    >
                        <SvgIcon>
                            <MessagesIcon/>
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            </Stack>
            <Box sx={{p: 0}}>
                {sortedThreads.length > 0 ? (
                    <List sx={{overscroll: 'auto', maxHeight: 350, p: 0}}>
                        {sortedThreads.map((thread, i) => {
                            return (
                                <ThreadItem
                                    key={'thread_list_' + i}
                                    topbar={true}
                                    onSelect={onClose}
                                    thread={thread}
                                    active={false}
                                />
                            )
                        })}
                    </List>
                ) : (
                    <div style={{height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {threadsLoaded ? (
                            <Typography variant="caption">
                                {t("core.no_chats")}
                            </Typography>
                        ) : (
                            <div style={{height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Typography variant="caption">
                                    {t('common.loading')}
                                </Typography>
                            </div>
                        )}
                    </div>
                )}
            </Box>
        </Popover>
    );
};

ChatPopover.propTypes = {
    anchorEl: PropTypes.any,
    contacts: PropTypes.array,
    onClose: PropTypes.func,
    open: PropTypes.bool
};
