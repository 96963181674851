export const SET_UNREAD = '@website/set-unread';
export const UPDATE_VISITORS = '@website/update-visitors';
export const UPDATE_VISITOR = '@website/update-visitor';
export const REMOVE_VISITOR = '@website/remove-visitors';
export const UPDATE_ACTIVITY = '@website/update-activity';
export const SET_NUMBER_ONLINE = '@website/set-number-online';
export const SET_NUMBER_VISITORS = '@website/set-number-visitors';

export function updateNumberOnline( numberOnline ){
    return dispatch => dispatch({
        type: SET_NUMBER_ONLINE,
        payload: {
            numberOnline
        }
    })
}

export function updateNumberVisitors( numberVisitors ){
    return dispatch => dispatch({
        type: SET_NUMBER_VISITORS,
        payload: {
            numberVisitors
        }
    })
}

export function updateVisitorActivity( vId, url, scrollPos){
    return dispatch => dispatch({
        type: UPDATE_ACTIVITY,
        payload: {
            vId, url, scrollPos
        }
    })
}

export function removeVisitor( visitorId ) {
    return dispatch => dispatch({
        type: REMOVE_VISITOR,
        payload: {
            visitorId
        }
    })
}

export function updateVisitor( visitor ) {
    return dispatch => dispatch({
        type: UPDATE_VISITOR,
        payload: {
            visitor
        }
    })
}

export function updateAllVisitors(data) {
    return dispatch => dispatch({
        type: UPDATE_VISITORS,
        payload: {
            data
        }
    })
}

export function setUnread( unread ) {

    return dispatch => dispatch({
        type: SET_UNREAD,
        payload: {
            unread
        }
    });
}
