import React, { createContext, useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Create the Context
export const AppModeContext = createContext();

// Create the Provider Component
export const AppModeProvider = ({ children }) => {
    const location = useLocation();
    const [isBackend, setIsBackend] = useState(location.pathname !== '/');
    const [isService, setIsService] = useState(false);
    const [service, setService] = useState(null);
    const [serviceTheme, setServiceTheme] = useState(null);
    const [backendTheme, setBackendTheme] = useState(null);
    const [websiteTheme, setWebsiteTheme] = useState(null);
    const [loading, setLoading] = useState(true);
    const isWebsite = useMemo(() => !isService && !isBackend, [isService, isBackend]);

    // Value to be passed to the context
    const value = useMemo(() => ({
        loading, setLoading,
        isService, isWebsite, isBackend, setIsService, setIsBackend,
        service, setService,
        serviceTheme, setServiceTheme, backendTheme, setBackendTheme, websiteTheme, setWebsiteTheme
    }), [
        loading, isService, isWebsite, isBackend, service, serviceTheme, backendTheme, websiteTheme
    ]);

    return (
        <AppModeContext.Provider value={value}>
            {children}
        </AppModeContext.Provider>
    );
};

export const AppModeConsumer = AppModeContext.Consumer;
