/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import produce from 'immer';
import _ from 'lodash';
import {
    GET_BOARD,
    CREATE_LIST,
    UPDATE_LIST,
    CHANGE_ATTACHMENTS,
    CLEAR_LIST,
    DELETE_LIST,
    CREATE_CARD,
    CHANGE_LABELS,
    UPDATE_CARD,
    ARCHIVE_LIST,
    CHANGE_MEMBERS,
    MOVE_CARD,
    DELETE_CARD,
    ADD_COMMENT,
    ADD_CHECKLIST,
    UPDATE_CHECKLIST,
    DELETE_CHECKLIST,
    UPDATE_CHECK_ITEM,
    DELETE_CHECK_ITEM,
    SHOW_ARCHIVED,
    HIDE_ARCHIVED,
    UPDATE_QUERY,
    UPDATE_LABELS,
    SET_SPACE_ID,
    ADD_UPDATE_CARDS,
    SET_LOADING,
    SET_BOARD_ID
} from 'src/omnia/store/actions/kanban-actions';
import objFromArray from 'src/omnia/utils/objFromArray';

// todo: rebuild the whole thing for many boards with ids
const initialState = {
    labels: [],
    filter: {
        showArchived: false,
        query: ""
    },
    // current board id
    boardId: -1,
    spaceId: -1,
    lists: {
        byId: {},
        allIds: []
    },
    cards: {
        byId: {},
        allIds: []
    },
    members: [],
    loading: true
};

const kanbanReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_LOADING: {
            return produce(state, (draft) => {
                draft.loading = action.payload;
            });
        }

        case SET_SPACE_ID: {
            return produce(state, (draft) => {
                draft.spaceId = action.payload;
            });
        }

        case UPDATE_QUERY: {
            return produce(state, (draft) => {
                draft.filter['query'] = action.payload;
            });
        }

        case SHOW_ARCHIVED: {
            return produce(state, (draft) => {
                draft.filter['showArchived'] = true;
            });
        }

        case HIDE_ARCHIVED: {
            return produce(state, (draft) => {
                draft.filter['showArchived'] = false;
            });
        }

        case UPDATE_LABELS: {
            return produce(state, (draft) => {
                draft.labels = action.payload;
            });
        }

        case SET_BOARD_ID: {
            // This also reset the board
            return produce(state, (draft) => {
                draft.boardId = action.payload;
                // Check if this board id is different
                if (state.boardId !== action.payload){
                    draft.cards.byId = {};
                    draft.cards.allIds = [];
                    draft.lists.byId = {};
                    draft.lists.allIds = [];
                    draft.members = [];
                }
            });
        }

        case ADD_UPDATE_CARDS: {
            const cardsToUpdate = action.payload;
            return produce(state, (draft) => {
                cardsToUpdate.forEach(card => {

                    // Handle the card
                    if (state.cards.byId[card.id]) {
                        // Update existing card
                        draft.cards.byId[card.id] = { ...state.cards.byId[card.id], ...card };
                    } else {
                        // Add new card
                        draft.cards.byId[card.id] = card;
                        draft.cards.allIds.push(card.id);
                    }

                    // Ensure card is added to the correct list
                    const list = draft.lists.byId[card.listId];
                    if (list) {
                        if (!list.cards.includes(card.id)) {
                            list.cards.push(card.id);
                        }
                        // Sort cards in the list based on their position
                        list.cards.sort((a, b) => {
                            const cardA = draft.cards.byId[a];
                            const cardB = draft.cards.byId[b];
                            return cardA.position - cardB.position;
                        });
                    }
                });
            });
        }

        case GET_BOARD: {

            const board = action.payload;

            return produce(state, (draft) => {

                // check if the current states boardId is different from this one
                if (state.boardId !== board.id){
                    draft.cards.byId = {};
                    draft.cards.allIds = [];
                }

                draft.labels = board.labels;
                draft.boardId = board.id;
                draft.lists.byId = objFromArray(board.lists);
                draft.lists.allIds = Object.keys(draft.lists.byId);
                draft.members = board.members;
            });
        }

        case CREATE_LIST: {
            const {list} = action.payload;

            if (state.lists.allIds.includes(list.id.toString()))
                return state;

            return produce(state, (draft) => {
                draft.lists.byId[list.id] = list;
                draft.lists.allIds.push(list.id.toString());
            });
        }

        case UPDATE_LIST: {
            const {list} = action.payload;

            return produce(state, (draft) => {
                // Update the list but with the current cards order (not per list serializer)
                draft.lists.byId[list.id] = {...list, ...{cards: draft.lists.byId[list.id].cards}};
            });
        }

        case CLEAR_LIST: {
            const {listId} = action.payload;

            return produce(state, (draft) => {
                const {cards} = draft.lists.byId[listId];

                draft.lists.byId[listId].cards = [];
                draft.cards.byId = _.omit(draft.cards.byId, cards);
                _.pull(draft.cards.allIds, ...cards);
            });
        }

        case ARCHIVE_LIST:
            const {listId} = action.payload;

            return produce(state, (draft) => {
                for (let i = 0; i < state.lists.byId[listId].length; i++)
                    draft.lists.byId[listId][i].is_archived = true;
            });

        case DELETE_LIST: {
            const {listId} = action.payload;

            if (!state.lists.allIds.includes(listId.toString()))
                return state;

            return produce(state, (draft) => {
                draft.lists.byId = _.omit(draft.lists.byId, listId);
                _.pull(draft.lists.allIds, listId.toString());
            });
        }

        case CREATE_CARD: {
            const {card} = action.payload;

            if (state.cards.allIds.includes(card.id.toString()))
                return state;

            return produce(state, (draft) => {
                draft.cards.byId[card.id] = card;
                draft.cards.allIds.push(card.id.toString());
                draft.lists.byId[card.listId].cards.push(card.id);
            });
        }

        case CHANGE_MEMBERS: {
            const {card} = action.payload;

            return produce(state, (draft) => {
                draft.cards.byId[card.id]['members'] = card.members;
            });
        }

        case CHANGE_LABELS: {
            const {card} = action.payload;

            return produce(state, (draft) => {
                draft.cards.byId[card.id]['labels'] = card.labels;
            });
        }

        case CHANGE_ATTACHMENTS: {
            const {card} = action.payload;

            return produce(state, (draft) => {
                draft.cards.byId[card.id]['attachments'] = card.attachments;
            });
        }

        case UPDATE_CARD: {
            const {card} = action.payload;

            return produce(state, (draft) => {
                // _.merge(draft.cards.byId[card.id], card);
                draft.cards.byId[card.id] = card;

                // Update the list order
                const list = draft.lists.byId[card.listId];
                if (list) {
                    list.cards.sort((a, b) => {
                        const cardA = draft.cards.byId[a];
                        const cardB = draft.cards.byId[b];
                        return cardA.position - cardB.position;
                    });
                }
            });
        }

        case MOVE_CARD: {

            const {cardId, position, listId} = action.payload;

            return produce(state, (draft) => {
                const {listId: sourceListId} = draft.cards.byId[cardId];

                // Remove card from source list
                _.pull(draft.lists.byId[sourceListId].cards, cardId);

                // If listId arg exists, it means that
                // we have to add the card to the new list
                if (listId) {
                    draft.cards.byId[cardId].listId = listId;
                    draft.lists.byId[listId].cards.splice(position, 0, cardId);
                } else {
                    draft.lists.byId[sourceListId].cards.splice(position, 0, cardId);
                }

            });
        }

        case DELETE_CARD: {
            const {cardId} = action.payload;

            if (!state.cards.allIds.includes(cardId.toString()))
                return state;

            return produce(state, (draft) => {
                const {listId} = draft.cards.byId[cardId];

                draft.cards.byId = _.omit(draft.cards.byId, cardId.toString());
                _.pull(draft.cards.allIds, cardId.toString());
                _.pull(draft.lists.byId[listId].cards, cardId);
            });
        }

        case ADD_COMMENT: {

            return produce(state, (draft) => {
                draft.cards.byId[parseInt(action.payload.cardId)].comments.push(action.payload.comment);
            });

        }

        case ADD_CHECKLIST: {
            return state;
            // return produce(state, (draft) => {
            //   draft.cards.byId[cardId].checklists.push(checklist);
            // });
        }

        case UPDATE_CHECKLIST: {
            const {cardId, checklist} = action.payload;

            return produce(state, (draft) => {
                const card = draft.cards.byId[cardId];

                card.checklists = _.map(card.checklists, (_checklist) => {
                    if (_checklist.id === checklist.id) {
                        return checklist;
                    }

                    return _checklist;
                });
            });
        }

        case DELETE_CHECKLIST: {
            const {cardId, checklistId} = action.payload;

            return produce(state, (draft) => {
                const card = draft.cards.byId[cardId];

                card.checklists = _.reject(card.checklists, {id: checklistId});
            });
        }

        case UPDATE_CHECK_ITEM: {
            const {
                cardId,
                checklistId,
                checkItemId,
                checkItem
            } = action.payload;

            return produce(state, (draft) => {
                const card = draft.cards.byId[cardId];

                card.checklists = _.map(card.checklists, (checklist) => {
                    if (checklist.id === checklistId) {
                        _.assign(checklist, {
                            checkItems: _.map(checklist.checkItems, (_checkItem) => {
                                if (_checkItem.id === checkItemId) {
                                    return checkItem;
                                }

                                return _checkItem;
                            })
                        });
                    }

                    return checklist;
                });
            });
        }

        case DELETE_CHECK_ITEM: {
            const {cardId, checklistId, checkItemId} = action.payload;

            return produce(state, (draft) => {
                const card = draft.cards.byId[cardId];

                card.checklists = _.map(card.checklists, (checklist) => {
                    if (checklist.id === checklistId) {
                        _.assign(checklist, {
                            checkItems: _.reject(checklist.checkItems, {id: checkItemId})
                        });
                    }

                    return checklist;
                });
            });
        }

        default: {
            return state;
        }
    }

};

export default kanbanReducer;
