import React, {useEffect, useState} from "react";
import {
    Card,
    CircularProgress,
    Divider,
    IconButton,
    Stack,
    SvgIcon,
    Tooltip,
    Typography,
} from "@mui/material";
import MaximizeIcon from "@untitled-ui/icons-react/build/esm/Maximize01";
import MinimizeIcon from "@untitled-ui/icons-react/build/esm/Minus";
import CloseIcon from "@untitled-ui/icons-react/build/esm/XClose";
import {useDispatch, useSelector} from "react-redux";
import {closeMiniThread, removeMiniThread} from "../../../../../store/actions/messages-actions";
import UserOnlineDot from "../../UserOnlineDot";
import {useTheme} from "@mui/system";
import { useNavigate } from "react-router";
import {ChatThread} from "../chat/chat-thread";
import {useTranslation} from "react-i18next";

export default function ChatWindow(){

    const theme = useTheme();
    const dispatch = useDispatch();
    const openMiniThread = useSelector(state => state.messages.openMiniThread);
    const assistantOpen = useSelector(state => state.messages.assistantOpen);
    const isChannel = openMiniThread && !(typeof(openMiniThread) === "number") ? (openMiniThread.users.length > 2) : false;
    const user = useSelector(state => state.account.user);
    const users = useSelector(state => state.users.users);
    const [members, setMembers] = useState(null);
    const [single, setSingle] = useState("");
    const navigate = useNavigate();
    const { t } = useTranslation();

    const minimizeChat = () => {
        dispatch(closeMiniThread());
    }

    const closeChat = () => {
        dispatch(removeMiniThread(openMiniThread.id));
        dispatch(closeMiniThread());
    }

    const maximizeChat = () => {
        if((openMiniThread && (typeof(openMiniThread) !== "number"))){
            navigate('/groon/chat/' + openMiniThread.id);
        }
    }

    const threadExists = (openMiniThread && (typeof(openMiniThread) !== "number"));

    useEffect(() => {
        if(openMiniThread && !(typeof(openMiniThread) === "number")){
            let memberIds = openMiniThread.users.map(u => u.id);
            setMembers(users.filter(u => memberIds.includes(u.id) && u.id !== user.id));
        } else {
            setMembers(null);
        }
    }, [openMiniThread, users]);

    useEffect(() => {
        let title = "";
        if(isChannel){
            if(typeof openMiniThread.title !== "undefined")
                title = openMiniThread.title;
        } else {
            if(members && members.length > 0)
                title = members[0].name;
        }
        setSingle(title !== "" ? title : "Deleted User");
    }, [members, openMiniThread, isChannel]);

    if(!openMiniThread || assistantOpen)
        return null;

    return (
        <Card
            style={{
                position: 'fixed',
                backgroundColor: theme.palette.background.default,
                width: 350,
                zIndex: 1500,
                bottom: 0,
                right: 65,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                boxShadow: theme.shadows[21],
            }}
        >
            {members || (openMiniThread === -1) ? (
                <>
                    <Stack
                        sx={{p: 2}}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Stack
                            direction="column"
                            spacing={0}
                        >
                            <Tooltip title="Vollbild-Chat anzeigen" placement="left">
                                <Typography
                                    variant="h6"
                                    onClick={maximizeChat}
                                    sx={{textTransform: 'none', cursor: 'pointer', textAlign: "left"}}
                                    color="textPrimary"
                                    underline="hover"
                                >
                                    {(openMiniThread && (typeof(openMiniThread) !== "number")) ? single : t('New Message')}
                                </Typography>
                            </Tooltip>
                            {((openMiniThread && (typeof(openMiniThread) !== "number")) && !isChannel && (members.length > 0)) && (
                                <UserOnlineDot userId={members[0].id} withText />
                            )}
                        </Stack>
                        <Stack direction="row">
                            {(openMiniThread && (typeof(openMiniThread) !== "number")) && (
                                <IconButton color="inherit" onClick={minimizeChat} size="large">
                                    <SvgIcon fontSize="small">
                                        <MinimizeIcon />
                                    </SvgIcon>
                                </IconButton>
                            )}
                            <IconButton color="inherit" onClick={closeChat} size="large">
                                <SvgIcon fontSize="small">
                                    <CloseIcon />
                                </SvgIcon>
                            </IconButton>
                        </Stack>
                    </Stack>
                    <Divider />
                    {threadExists && (
                        <div style={{
                            flexGrow: 1,
                            display: 'flex',
                            height: 460,
                            flexDirection: 'column',
                            backgroundColor: theme.palette.background.dark
                        }}>
                            <ChatThread
                                threadKey={'' + openMiniThread.id}
                                noToolbar={true}
                                smallVersion={true}
                            />
                        </div>
                    )}
                </>
            ) : (
                <div style={{height: 400, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <CircularProgress />
                </div>
            )}
        </Card>
    );

}