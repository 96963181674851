/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import {
    REMOVE_PRODUCT,
    UPDATE_PRODUCTS,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_ATTRIBUTE,
    UPDATE_PRODUCT_ATTRIBUTES,
    REMOVE_PRODUCT_ATTRIBUTE,
    UPDATE_VARIANT
} from '../actions/products-actions';
import produce from "immer";
import _ from "lodash";

const initialState = {
    products: [],
    productAttributes: [], // {id: 2, name: "", values: []}
    initialized: false
};

const productReducer = (state = initialState, action) => {

    switch (action.type) {

        case UPDATE_VARIANT: {
            const { variant } = action.payload;
            return produce(state, draft => {
                // TODO: work in progress
                console.log(variant);
            })
        }

        case UPDATE_PRODUCT_ATTRIBUTES: {
            const { attributes } = action.payload;
            return produce(state, draft => {
                draft.productAttributes = attributes;
            })
        }

        case UPDATE_PRODUCT_ATTRIBUTE: {
            const { attribute } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.productAttributes, {'id': attribute.id});
                if(index !== -1){
                    draft.productAttributes[index] = {...draft.productAttributes[index], ...attribute};
                } else {
                    draft.productAttributes = state.productAttributes.concat([attribute]);
                }
            });
        }

        case UPDATE_PRODUCTS: {
            const { data } = action.payload;
            return produce(state, draft => {
                draft.products = data;
                draft.initialized = true;
            })
        }

        case UPDATE_PRODUCT: {
            const { product } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.products, {'id': product.id});
                if(index !== -1){
                    // TODO: if this ECMA2018 works better then lodash, we should change it everywhere
                    draft.products[index] = {...draft.products[index], ...product};
                    // draft.products[index] = _.merge(draft.products[index], product);
                } else {
                    draft.products = state.products.concat([product]);
                }
            });
        }

        case REMOVE_PRODUCT: {
            const { productId } = action.payload;
            return produce(state, draft => {
                draft.products = state.products.filter(v => v.id !== parseInt(productId));
            });
        }

        case REMOVE_PRODUCT_ATTRIBUTE: {
            const { attributeId } = action.payload;
            return produce(state, draft => {
                draft.productAttributes = state.productAttributes.filter(v => v.id !== parseInt(attributeId));
            });
        }

        default: {
            return state;
        }
    }
};

export default productReducer;
