import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {APP_SETTING} from "../../../../setup";
import {useSelector} from "react-redux";
import {useSecurityCheck} from "../../../hooks/use-security-check";

export const useSections = () => {
    /**
     *
     * TODO: this component can be improved!! When e.g. switching between admin and rest, the menu is for a millisecond
     * empty. This is because the useSections is loaded in the Layout component and should instead be moved up!
     *
     */

    const {t} = useTranslation();
    const {hasRights} = useSecurityCheck();
    const unread = useSelector((state) => state.messages.unread);
    const user = useSelector((state) => state.account.user);
    const unreadRef = useRef();
    unreadRef.current = unread;

    const processItem = (item) => {

        if(!item)
            return null;

        // Translate the title
        if (item.title) {
            item.title = t(item.title);
        }

        // Include chips
        if (item.chip) {
            if(item.chip === 'unread') {
                item.chip = unreadRef.current;
            } else if(item.chip === 'tickets') {
                item.chip = user?.open_requests || 0;
            } else {
                console.log('WARNING: Chip could not be processed', item)
            }

        }

        // Check if this should be shown
        if (item?.showIf){
            if(item?.showIf === 'has_tickets'){
                if(user?.open_requests === 0){
                    return null;
                }
            }
        }

        // If the item has nested items, process them recursively
        if (item.items) {
            item.items = item.items.map(processItem).filter(Boolean);
        }

        // Check permissions if they exist, return null to filter out if checkRights fails
        if (!item.permissions || hasRights(item.permissions, 'or')) {
            return item;
        } else {
            return null;
        }
    };

    const getProcessedNavigation = (menuData) => {

        const clonedMenuData = structuredClone(menuData);

        const result = clonedMenuData.map(section => {
            // Translate the section titles if present
            if (section.subheader) {
                section.subheader = t(section.subheader);
            }

            // Process each item within the section
            if (section.items) {
                section.items = section.items.map(processItem).filter(Boolean);
            }

            return section;
        }).filter(section => section.items && section.items.length > 0);

        return result;
    };

    return getProcessedNavigation(APP_SETTING?.navigation || []);
};

export const defaultNavigationConfig = {
    core: {
        items: [
            {
                title: 'layout.home',
                path: '/groon/launchpad',
                icon: 'Rocket02',
            },
            {
                title: "layout.my_tasks",
                path: '/groon/tasks',
                // permissions: [],
                icon: 'CheckCircle',
            },
            {
                title: "layout.my_files",
                path: '/groon/files',
                permissions: ['my_files'],
                icon: 'File02',
            },
            {
                title: "layout.my_chats",
                path: '/groon/chat',
                icon: 'MessageChatCircle',
                permissions: ['groon_chats'],
                chip: 'unread',
            },
            {
                title: "layout.tickets",
                path: '/groon/requests',
                icon: 'Bookmark',
                showIf: 'has_tickets',
                chip: 'tickets',
            },
            {
                title: "layout.data_analytics.title",
                path: '/groon/analytics',
                permissions: ['dashboards'],
                icon: 'Data',
                items: [
                    {
                        title: 'layout.data_analytics.dashboards',
                        path: '/groon/analytics/dashboards'
                    },
                    {
                        title: 'layout.data_analytics.data_hub',
                        permissions: ['omnia_data_overview'],
                        path: '/groon/analytics/datahub'
                    },
                    {
                        title: "layout.my_links",
                        path: '/groon/short-links',
                        permissions: ['my_short_links'],
                        icon: 'Link03',
                    },
                ]
            },
        ]
    },
    erp: {
        title: "layout.resources.title",
        path: '/groon/resources',
        icon: 'Atom01',
        permissions: ['erp'],
        items: [
            {
                title: 'common.overview',
                permissions: ['erp_financial_transactions', 'erp_warehouse_management_transactions', 'erp_allow_time_booking'],
                path: '/groon/resources'
            },
            {
                title: 'layout.resources.transactions',
                permissions: ['erp_financial_transactions', 'erp_warehouse_management_transactions', 'erp_allow_time_booking'],
                path: '/groon/resources/transactions'
            },
            {
                title: "layout.resources.products_services_short",
                permissions: ['tms_services_products'],
                path: '/groon/resources/products',
            },
            {
                title: "layout.resources.orders",
                permissions: ['tms_orders', 'tms_offers'],
                path: '/groon/resources/orders',
            },
            {
                title: "layout.resources.invoices",
                permissions: ['tms_invoices'],
                path: '/groon/resources/invoices',
            },
            {
                title: "common.settings",
                permissions: ['tms_settings'],
                path: '/groon/resources/settings',
            },
        ]
    },
    pm: {
        title: "layout.projects.title",
        path: '/groon/projects',
        icon: 'Headphones02',
        permissions: ['spaces'],
        items: [
            {
                title: "common.overview",
                path: '/groon/projects/index'
            },
            {
                title: "layout.projects.browse",
                path: '/groon/projects/browse'
            },
        ]
    },
    crm: {
        title: "layout.connections.title",
        path: '/groon/connections',
        icon: 'Users02',
        permissions: ['contacts'],
        items: [
            {
                title: "common.overview",
                path: '/groon/connections/dashboard'
            },
            // {
            //     title: "layout.connections.tasks",
            //     path: '/groon/connections/jobs'
            // },
            {
                title: "layout.connections.contexts",
                path: '/groon/connections/registrations'
            },
            {
                title: "layout.connections.contacts",
                path: '/groon/connections/index'
            },
        ]
    },
    cms: {
        title: "layout.marketing.title",
        path: '/groon/marketing',
        icon: 'Globe04',
        permissions: ['contents_module'],
        items: [
            {
                title: "common.overview",
                path: '/groon/marketing',
            },
            {
                title: "layout.marketing.posts",
                path: '/groon/marketing/posts',
            },
            {
                title: "layout.marketing.media_library",
                path: '/groon/marketing/files-overview',
            },
            {
                title: "layout.marketing.online_presences",
                path: '/groon/marketing/presences',
            },
            {
                title: "layout.marketing.campaigns",
                path: '/groon/marketing/campaigns',
            },
            // {
            //     title: "layout.marketing.competitors",
            //     path: '/groon/marketing/competitors',
            // },
            {
                title: "common.settings",
                path: '/groon/marketing/settings',
            },
        ]
    },
    ecs: null,
    sm: null,
}