import React, {memo, useEffect, useCallback, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNotifications} from "src/omnia/hooks/use-notifications";
import useSocket from "src/omnia/hooks/use-socket";
import {toast} from "react-hot-toast";
import {setErrorSynced} from "../../store/actions/client-actions";
import {Typography} from "@mui/material";
import {useTheme} from "@mui/system";
import {storeUserGroups} from "../../store/actions/user-groups-actions";
import {storeRights, storeRightsOptions} from "../../store/actions/rights-actions";
import {useSettings} from "../../hooks/use-settings";
import {updateRequestCount} from "../../store/actions/admin-actions";
import authService from "../../services/auth-service";
import {useTranslation} from "react-i18next";
import debounce from "lodash.debounce";
import {updateDataUpdate} from "../../store/actions/datatable-actions";
import {
    addLatestMessage,
    addNewMessage,
    addThread,
    otherUserIsWriting,
    storeThreads,
    updateAssistantUnread
} from "../../store/actions/messages-actions";
import {
    storeContacts,
    storeUsers,
    updateActivityStatus,
    updateUser,
    updateVisibilityStatus
} from "../../store/actions/users-actions";
import {
    setGpuAvailable,
    setNewReport,
    setUnreadNotifications,
    setUserData, updateLaunchpad,
    updateWorkingHours
} from "../../store/actions/account-actions";
import {
    removeSite, removeVisitor,
    updateSite,
    updateSites,
    updateSiteUrl,
    updateVisitor,
    updateVisitorActivity,
    updateVisitors,
    updateVisitorVisibility
} from "../../store/actions/cms-system-actions";

function Wireless(){

    const { notify, flashDesktopNotification, isScreenVisible } = useNotifications();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const settings = useSettings();
    const user = useSelector(state => state.account.user);
    const reconnectTimestamp = useSelector(state => state.account.wirelessReconnect);
    const isWriting = useSelector(state => state.messages.isWriting);
    const errors = useSelector(state => state.client.errors);
    const [ initialConnect, setInitialConnect ] = useState(true);
    const synchronized = useSelector(state => state.datatable.synchronized);
    const tableStates = useSelector(state => state.datatable.tableStates);
    const socketOfflineId = useRef(null);
    const onMessageCallback = useCallback((packet) => {

        switch (packet.kind) {
            case "requests-count": {
                dispatch(updateRequestCount(packet.data));
                break;
            }
            case "gpuAvailable": {
                dispatch(setGpuAvailable(packet.data));
                break;
            }
            case "working-hours": {
                dispatch(updateWorkingHours(packet.data));
                break;
            }
            case "launchpad": {
                dispatch(updateLaunchpad(packet.data));
                break;
            }
            case "unreadAIChat": {
                dispatch(updateAssistantUnread(packet.data));
                break;
            }
            case "visitorActivity":
                dispatch(updateVisitorActivity(packet['activity'], packet['visitor_id']));
                break;
            case "visitorVisibility":
                dispatch(updateVisitorVisibility(packet['visitor_id'], packet['status']));
                break;
            case "visitorUpdate":
                dispatch(updateVisitor(packet['visitor']));
                break;
            case "visitorRemoval":
                dispatch(removeVisitor(packet['visitor_id']));
                break;
            case "siteUpdate":
                dispatch(updateSite(packet['site']));
                break;
            case "removeSite":
                dispatch(removeSite(packet['siteId']));
                break;
            case "shopUrl":
                dispatch(updateSiteUrl(packet['url']));
                break;
            case "allSites":
                dispatch(updateSites(packet['sites']));
                break;
            case "visitors":
                dispatch(updateVisitors(packet['visitors']));
                break;
            case "data-update":
                dispatch(updateDataUpdate(packet['endpoint']));
        }

        switch (packet.type) {
            case "report": {
                if(!packet.is_initial)
                    notify("Neuer Report: " + packet.data.title, "info", true);
                dispatch(setNewReport(packet.data));
                break;
            }
            case "userUpdate": {
                dispatch(updateUser(packet.data));
                break;
            }
            case "updateMe": {
                dispatch(setUserData(packet.data));
                break;
            }
            case "rotate-token": {
                const oldToken = authService.getAccessToken();
                authService.setSession(packet.token);
                sendMessage({
                    type: 'removeToken',
                    token: oldToken
                })

                break;
            }
            case "userData": {
                dispatch(storeUsers(packet.data));
                break;
            }
            case "contactsData": {
                dispatch(storeContacts(packet.data));
                break;
            }
            case "rightOptions": {
                dispatch(storeRightsOptions(packet.data));
                break;
            }
            case "threadData": {
                dispatch(storeThreads(packet.data));
                break;
            }
            case "rightsData": {
                dispatch(storeRights(packet.data));
                break;
            }
            case "userGroupData": {
                dispatch(storeUserGroups(packet.data));
                break;
            }
            case "unreadNotifications": {
                dispatch(setUnreadNotifications(packet.unread));
                break;
            }
            case "notification": {
                notify(packet.data.message, "info", true);
                dispatch(setUnreadNotifications(packet.unread));
                break;
            }
            case "message": {
                dispatch(addLatestMessage(packet.data.thread, packet.data));
                dispatch(addNewMessage(packet.data));
                break;
            }
            case "thread": {
                // dispatch the new thread
                dispatch(addThread(packet.data));
                break;
            }
            case "status_change":{
                // dispatch change in user status
                dispatch(updateActivityStatus(packet.data.id, packet.data.status, packet.data.last_active));
                break;
            }
            case "visibility_change": {
                // dispatch change in user status
                dispatch(updateVisibilityStatus(packet.data.id, packet.data.is_visible, packet.data.last_active));
                break;
            }
            case "visitor_message": {
                // todo: maybe flash notification here?
                if(!isScreenVisible() && !packet['message']['message']['is_system_message'])
                    flashDesktopNotification(packet['message']['message'], 'Besucher Nachricht')
                break;
            }
            case "userIsWriting":
                dispatch(otherUserIsWriting(packet['userId'], packet['isWriting'], packet['threadId']));
                break;
        }

    }, [dispatch]);
    const { sendMessage, isConnected } = useSocket('wireless', onMessageCallback);
    const reconnectingRef = useRef();
    const isConnectedRef = useRef();
    const intervalRef = useRef(null);
    const themeRef = useRef();
    isConnectedRef.current = isConnected;
    reconnectingRef.current = reconnectTimestamp;
    themeRef.current = theme;

    const triggerReconnecting = (connectionLost) => {
        if (connectionLost) {

            const updateCountdown = () => {
                toast.dismiss(socketOfflineId.current);
                if(reconnectingRef.current){
                    const now = Date.now();
                    const remainingTime = Math.max(0, Math.floor((reconnectingRef.current - now) / 1000));
                    socketOfflineId.current = toast.loading(
                        <Typography variant="body1" color="textPrimary">
                            {remainingTime > 0 ? t('core.connection_lost_sec', { seconds: remainingTime }) : t('core.connection_reloading')}
                        </Typography>,
                        {
                            id: socketOfflineId.current,
                            style: {
                                backgroundColor: themeRef.current.palette.background.default,
                            },
                        }
                    );

                    if (remainingTime <= 0) {
                        intervalRef.current && clearInterval(intervalRef.current);
                        intervalRef.current = null;
                    }
                } else {
                    // intervalRef.current && clearInterval(intervalRef.current);
                    // intervalRef.current = null;
                }
            };

            if(reconnectingRef.current){
                // Initiate an interval
                intervalRef.current = setInterval(updateCountdown, 1000);

                // Show the toast
                socketOfflineId.current = toast.loading(
                    <Typography variant="body1" color="textPrimary">
                        {reconnectingRef.current ? t('core.connection_lost_sec', { seconds: 60 }) : t('core.connection_lost')}
                    </Typography>,
                    {
                        style: {
                            backgroundColor: themeRef.current.palette.background.default,
                        },
                    }
                );

                // Start the countdown
                updateCountdown();
            } else {
                socketOfflineId.current = toast.loading(
                    <Typography variant="body1" color="textPrimary">
                        {t('core.connection_lost')}
                    </Typography>,
                    {
                        style: {
                            backgroundColor: themeRef.current.palette.background.default,
                        },
                    }
                );
            }

        }
    };

    const queueReconnecting = useCallback(debounce(triggerReconnecting, 2000), []);

    useEffect(() => {
        if(user){
            if(isConnected){

                queueReconnecting(false);

                if(socketOfflineId.current){

                    toast.dismiss(socketOfflineId.current);
                    socketOfflineId.current = null;
                    intervalRef.current && clearInterval(intervalRef.current);
                    intervalRef.current = null;

                    notify(t('core.connection_back'), 'success');

                }
            } else {
                if(!initialConnect){

                    // If there is a toast already, dismiss it
                    if(socketOfflineId.current)
                        toast.dismiss(socketOfflineId.current);

                    // Start the "loading" dropdown
                    queueReconnecting(true);

                } else {
                    setInitialConnect(false);
                }
            }
        } else {
            if(socketOfflineId.current)
                toast.dismiss(socketOfflineId.current);
            socketOfflineId.current = null;
        }
    }, [isConnected]);

    useEffect(() => {
        if(user && isWriting){
            sendMessage({
                type: 'userWriting',
                userId: user.id,
                threadId: isWriting
            })
        }
    }, [isWriting]);

    useEffect(() => {
        // sync those errors with groon
        for(let i = 0; i < errors.length; i++){
            sendMessage({
                'type': 'errorReport',
                'data': errors[i]
            });
            dispatch(setErrorSynced(errors[i].id));
        }
    }, [errors]);

    useEffect(() => {
        if(synchronized){
            sendMessage({
                'type': 'tableSetting',
                'states': JSON.stringify(tableStates)
            });
        }
    }, [tableStates]);

    useEffect(() => {
        if(settings){
            sendMessage({
                'type': 'languageChange',
                'states': settings.language
            });
        }
    }, [settings?.language]);

    return null;

}

export default memo(Wireless, () => true);
