import React from "react";
import {alpha} from "@mui/material/styles";
import {Box} from "@mui/material";
import PropTypes from "prop-types";

function BlurredBox({children, sx, style, ...other}){
    return (
        <Box
            sx={{
                ...{
                    backdropFilter: 'blur(6px)',
                    backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
                },
                ...sx,
                ...style
            }}
            {...other}
        >
            {children}
        </Box>
    )
}

BlurredBox.propTypes = {
    sx: PropTypes.object,
    style: PropTypes.object,
    children: PropTypes.node
}

export default BlurredBox;