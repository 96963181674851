export const SAVE_STATE = '@datatable/save-state';
export const LOAD_SETTING = '@datatable/load-setting';
export const UPDATE_DATE_UPDATE = '@datatable/update-details-update';

export function updateDataUpdate(endpoint){
    return dispatch => dispatch({
        type: UPDATE_DATE_UPDATE,
        payload: {
            endpoint
        }
    })
}

export function loadTableSettings(settings){
    return dispatch => dispatch({
        type: LOAD_SETTING,
        payload: {
            settings
        }
    })
}

export function saveGridState(ident, gridState){
    return dispatch => dispatch({
        type: SAVE_STATE,
        payload: {
            ident, gridState
        }
    })
}