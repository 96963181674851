/* eslint-disable react/no-array-index-key */
import React, {lazy, Suspense} from 'react';
import {Navigate, Outlet} from 'react-router';
import { Layout as OmniaLayout } from 'src/omnia/components/layouts/dashboard';
import { Layout as AuthLayout } from 'src/omnia/components/layouts/auth/classic-layout';
import AuthGuard from 'src/omnia/components/general/auth/AuthGuard';
import LoadingScreen from "./components/general/loading-screen";
import GuestGuard from "./components/general/auth/GuestGuard";

// Pages
const Login = lazy(() => import('src/omnia/views/pages/login'));
const Setup = lazy(() => import('src/omnia/views/pages/setup'));
const Reset = lazy(() => import('src/omnia/views/pages/reset'));
const NotFound = lazy(() => import('src/omnia/views/pages/404'));

// Core
const CoreIndex = lazy(() => import('src/omnia/views/core'));
const CoreOrganization = lazy(() => import('src/omnia/views/core/organization'));
const Dashboard = lazy(() => import('src/omnia/views/core/dashboards'));
const DataHub = lazy(() => import('src/omnia/views/core/data-hub'));
const Chat = lazy(() => import('src/omnia/views/core/chat'));
const Profile = lazy(() => import('src/omnia/views/core/profile'));
const Team = lazy(() => import('src/omnia/views/core/group'));
const MyFiles = lazy(() => import("src/omnia/views/core/my-files"))
const ShortLinks = lazy(() => import('src/omnia/views/core/short-links'));
const MyTasks = lazy(() => import('src/omnia/views/core/my-tasks'));
const MyTickets = lazy(() => import('src/omnia/views/core/my-tickets'));
const LearningCenter = lazy(() => import('src/omnia/views/core/learning-center'));
const Workflows = lazy(() => import('src/omnia/views/core/workflows'));
const Workflow = lazy(() => import('src/omnia/views/core/workflow'));
const WorkflowExecution = lazy(() => import('src/omnia/views/core/workflow-exec'));

// Management
const ManagementIndex = lazy(() => import('src/omnia/views/management'));
const ManagementSettings = lazy(() => import('src/omnia/views/management/settings'));
const JobDetail = lazy(() => import('src/omnia/views/management/job-details'));
const Device = lazy(() => import("src/omnia/views/management/device"));
const ThemeBuilder = lazy(() => import("src/omnia/views/management/theme-builder"));

// Marketing
const MarketingIndex = lazy(() => import('src/omnia/views/marketing'));
const MarketingAnalytics = lazy(() => import('src/omnia/views/marketing/cms'));
const MarketingCompetitors = lazy(() => import('src/omnia/views/marketing/competitors'));
const MarketingCompetitor = lazy(() => import('src/omnia/views/marketing/competitor'));
const MarketingVisitor = lazy(() => import('src/omnia/views/marketing/cms/visitor'));
const MarketingSession = lazy(() => import('src/omnia/views/marketing/cms/session'));
const MarketingCompetitiveContent = lazy(() => import('src/omnia/views/marketing/competitive-content')); // TODO: name
const MarketingSettings = lazy(() => import('src/omnia/views/marketing/settings'));
const MarketingMediaLibrary = lazy(() => import('src/omnia/views/marketing/media-library'));
const MarketingSite = lazy(() => import('src/omnia/views/marketing/cms/site'));
const MarketingDictionaryEntry = lazy(() => import('src/omnia/views/marketing/cms/dictionary-entry'));
const MarketingArticle = lazy(() => import('src/omnia/views/marketing/cms/article'));
const MarketingTranslations = lazy(() => import('src/omnia/views/marketing/cms/translations'));
const MarketingPlatforms = lazy(() => import('src/omnia/views/marketing/platforms'));
const MarketingPresences = lazy(() => import('src/omnia/views/marketing/presences'));
const MarketingPosts = lazy(() => import('src/omnia/views/marketing/posts'));
const MarketingCampaigns = lazy(() => import('src/omnia/views/marketing/campaigns'));
const MarketingCampaign = lazy(() => import('src/omnia/views/marketing/campaign'));
const MarketingBuilder = lazy(() => import('src/omnia/views/marketing/cms/cms-builder'));  // External
const MarketingFacebookCallback = lazy(() => import('src/omnia/views/marketing/callbacks/facebook'));  // External

// Resources
const ResourcesIndex = lazy(() => import('src/omnia/views/resources/index'));
const ResourcesFinancialAccount = lazy(() => import('src/omnia/views/resources/financial-accounting/account'));
const ResourcesWarehouseManagement = lazy(() => import('src/omnia/components/modules/resources/warehouse-management'));
const ResourcesTransactions = lazy(() => import('src/omnia/views/resources/transactions'));
const ResourcesTransaction = lazy(() => import('src/omnia/views/resources/transaction'));
const ResourcesOffer = lazy(() => import('src/omnia/views/resources/offer'));
const ResourcesInvoices = lazy(() => import('src/omnia/views/resources/invoices'));
const ResourcesInvoice = lazy(() => import('src/omnia/views/resources/invoice'));
const ResourcesOrders = lazy(() => import('src/omnia/views/resources/orders'));
const ResourcesOrder = lazy(() => import('src/omnia/views/resources/order'));
const ResourcesNewOrder = lazy(() => import('src/omnia/views/resources/new-order'));
const ResourcesSubscriptions = lazy(() => import('src/omnia/views/resources/subscriptions'));
const ResourcesSubscription = lazy(() => import('src/omnia/views/resources/subscription'));
const ResourcesProducts = lazy(() => import('src/omnia/views/resources/products'));
const ResourcesProduct = lazy(() => import('src/omnia/views/resources/product'));
const ResourcesSettings = lazy(() => import('src/omnia/views/resources/settings'));

// Projects
const ProjectsIndex = lazy(() => import('src/omnia/views/projects'));
const ProjectsProject = lazy(() => import('src/omnia/views/projects/project'));
const ProjectsCreate = lazy(() => import('src/omnia/views/projects/create'));
const ProjectsFinished = lazy(() => import('src/omnia/views/projects/finished'));
const ProjectsTasks = lazy(() => import('src/omnia/views/projects/tasks'));
const ProjectsBrowse = lazy(() => import('src/omnia/views/projects/browse'));

// Connections
const ConnectionsIndex = lazy(() => import('src/omnia/views/connections'));
const ConnectionsSettings = lazy(() => import('src/omnia/views/connections/settings'));
const ConnectionsContacts = lazy(() => import('src/omnia/views/connections/contacts'));
const ConnectionsCustomer = lazy(() => import('src/omnia/views/connections/contact'));
const ConnectionsContexts = lazy(() => import('src/omnia/views/connections/contexts'));
const ConnectionsContext = lazy(() => import('src/omnia/views/connections/context'));
const ConnectionsTasks = lazy(() => import('src/omnia/views/connections/tasks'));
const ConnectionsLogin = lazy(() => import('src/omnia/views/connections/login'));  // External
const ConnectionsRegister = lazy(() => import('src/omnia/views/connections/register'));  // External
const ConnectionsOptOut = lazy(() => import('src/omnia/views/connections/opt-out'));  // External
const ConnectionsOptIn = lazy(() => import('src/omnia/views/connections/opt-in'));  // External

// Intelligence
const IntelligenceIndex = lazy(() => import('src/omnia/views/intelligence'));


export const mergeRoutes = (customRoutes=[]) => {

    // Create temporary variables
    let hasPm = true;
    let hasCrm = true;
    let hasErp = true;
    let hasCms = true;

    // Initiate routes object
    let routesConfig = [];

    // If no CMS is in place, initiate a redirect as access point
    if(!hasCms){
        routesConfig.push({
            index: true,
            element: <Navigate to="/omnia" />
        });
    }

    // Append login, setup and reset-password view
    routesConfig = routesConfig.concat([

        // GROON Authentication
        {
            path: '/login',
            element: <Login />,
        },
        {
            path: '/setup',
            element: <Setup />,
        },
        {
            path: '/reset/:key',
            element: <Reset />,
        },

        // GROON Modules External Interfaces
        {
            path: '/on/register/:contextId',
            element: <ConnectionsRegister />
        },
        {
            path: '/on/login/:contextId',
            element: <ConnectionsLogin />
        },
    ])

    // Initialize Omnia Core Routes
    let omniaRoutes = [
        {
            // rights placed
            path: '/groon',
            element: <Navigate to="/groon/launchpad" />
        },
        {
            // rights placed
            path: '/groon/launchpad/:url?/:identifier?',
            element: <CoreIndex />
        },
        {
            // rights placed
            path: '/groon/organization/:postId?',
            element: <CoreOrganization />
        },
        {
            // rights placed
            path: '/groon/analytics/dashboards',
            element: <Dashboard />
        },
        {
            // rights placed
            path: '/groon/analytics/datahub',
            element: <DataHub />
        },
        {
            // rights placed
            path: '/groon/files',
            element: <MyFiles />
        },
        {
            path: '/groon/workflows',
            element: <Workflows />
        },
        {
            path: '/groon/workflows/:workflowId',
            element: <Workflow />
        },
        {
            path: '/groon/workflow-executions/:executionId',
            element: <WorkflowExecution />
        },
        {
            // rights placed
            path: '/groon/short-links',
            element: <ShortLinks />
        },
        {
            path: '/groon/tasks',
            element: <MyTasks />
        },
        {
            path: '/groon/requests',
            element: <MyTickets />
        },
        {
            // rights placed
            path: '/groon/team/:groupId',
            element: <Team />
        },
        {
            // rights placed
            path: '/groon/profile',
            element: <Profile />
        },
        {
            // rights placed
            path: '/groon/profile/:userId',
            element: <Profile />
        },
        {
            path: '/groon/chat',
            element: <Chat />
        },
        {
            path: '/groon/chat/:threadKey',
            element: <Chat />
        },
        {
            path: '/groon/leaning-center',
            element: <LearningCenter />
        },
        {
            path: '/groon/device/:deviceId',
            element: <Device />
        },
        {
            path: '/groon/themes',
            element: <ThemeBuilder />
        },
        {
            exact: true,
            path: '/groon/control',
            element: <ManagementIndex />
        },
        {
            exact: true,
            path: '/groon/control/settings',
            element: <ManagementSettings />
        },
        {
            exact: true,
            path: '/groon/control/job/:jobId',
            element: <JobDetail />
        },
    ];

    // Attach CMS System if applicable
    if(hasCms){
        omniaRoutes = omniaRoutes.concat([
            {
                // rights placed
                path: '/groon/marketing',
                element: <MarketingIndex />
            },
            {
                // rights placed
                path: '/groon/marketing/settings',
                element: <MarketingSettings />
            },

            // Website
            {
                // rights placed
                path: '/groon/marketing/translations',
                element: <MarketingTranslations />
            },
            {
                // rights placed
                path: '/groon/marketing/website/site/:siteId?',
                element: <MarketingSite />
            },
            {
                // rights placed
                path: '/groon/marketing/articles/:articleId?',
                element: <MarketingArticle />
            },
            {
                // rights placed
                path: '/groon/marketing/entries/:entryId?',
                element: <MarketingDictionaryEntry />
            },
            {
                // rights placed
                path: '/groon/marketing/website',
                element: <MarketingAnalytics />
            },
            {
                // rights placed
                path: '/groon/marketing/visitors/:visitorId?',
                element: <MarketingVisitor />
            },
            {
                // rights placed
                path: '/groon/marketing/sessions/:sessionId?',
                element: <MarketingSession />
            },
            {
                // rights placed
                path: '/groon/marketing/content/:contentId?',
                element: <MarketingCompetitiveContent />
            },

            // Media
            {
                // rights placed
                path: '/groon/marketing/files-overview',
                element: <MarketingMediaLibrary />
            },

            // Competitors
            {
                // rights placed
                path: '/groon/marketing/competitors',
                element: <MarketingCompetitors />
            },
            {
                // rights placed
                path: '/groon/marketing/competitor/:competitorId?',
                element: <MarketingCompetitor />
            },

            // Online Marketing
            {
                path: '/groon/marketing/platforms',
                element: <MarketingPlatforms />
            },
            {
                path: '/groon/marketing/presences',
                element: <MarketingPresences />
            },
            {
                path: '/groon/marketing/posts',
                element: <MarketingPosts />
            },
            {
                path: '/groon/marketing/campaigns',
                element: <MarketingCampaigns />
            },
            {
                path: '/groon/marketing/campaigns/:campaignId',
                element: <MarketingCampaign />
            },
        ])
    }

    // Attach ERP system if applicable
    if(hasErp){
        omniaRoutes = omniaRoutes.concat([

            // Index
            {
                path: '/groon/resources',
                element: <ResourcesIndex />
            },

            // Transaction Templates
            {
                path: '/groon/resources/transactions',
                element: <ResourcesTransactions />
            },
            {
                path: '/groon/resources/transaction/:id',
                element: <ResourcesTransaction />
            },

            // Financial Accounting
            {
                path: '/groon/resources/financial-accounting/account/:accountId',
                element: <ResourcesFinancialAccount />
            },

            // Warehouse Management
            {
                path: '/groon/resources/warehouse-management',
                element: <ResourcesWarehouseManagement />
            },

            // TMS
            {
                path: '/groon/resources/settings',
                element: <ResourcesSettings />
            },
            {
                path: '/groon/resources/products',
                element: <ResourcesProducts />
            },
            {
                path: '/groon/resources/offer/:offerId?',
                element: <ResourcesOffer />
            },
            {
                path: '/groon/resources/invoices',
                element: <ResourcesInvoices />
            },
            {
                path: '/groon/resources/invoices/:invoiceId',
                element: <ResourcesInvoice />
            },
            {
                path: '/groon/resources/product/:productId?',
                element: <ResourcesProduct />
            },
            {
                path: '/groon/resources/orders',
                element: <ResourcesOrders />
            },
            {
                path: '/groon/resources/new_order',
                element: <ResourcesNewOrder />
            },
            {
                path: '/groon/resources/orders/:orderId',
                element: <ResourcesOrder />
            },
            {
                path: '/groon/resources/subscriptions',
                element: <ResourcesSubscriptions />
            },
            {
                path: '/groon/resources/subscriptions/:subscriptionId',
                element: <ResourcesSubscription />
            },
        ])
    }

    // Attach PM system if applicable
    if(hasPm){
        omniaRoutes = omniaRoutes.concat([
            {
                path: '/groon/projects',
                element: () => <Navigate to="/groon/projects/index"/>
            },
            {
                path: '/groon/projects/index',
                element: <ProjectsIndex />
            },
            {
                path: '/groon/projects/jobs',
                element: <ProjectsTasks />
            },
            {
                path: '/groon/projects/view/:spaceId/:content/:id?',
                element: <ProjectsProject />
            },
            {
                path: '/groon/projects/finished/:spaceId',
                element: <ProjectsFinished />
            },
            {
                path: '/groon/projects/add',
                element: <ProjectsCreate />
            },
            {
                path: '/groon/projects/browse',
                element: <ProjectsBrowse />
            }
        ])
    }

    // Attach Connections system if applicable
    if(hasCrm){
        omniaRoutes = omniaRoutes.concat([
            {
                path: '/groon/connections',
                element: <Navigate to="/groon/connections/index"/>
            },
            {
                path: '/groon/connections/registrations',
                element: <ConnectionsContexts />
            },
            {
                path: '/groon/connections/registration/:contextId',
                element: <ConnectionsContext />
            },
            {
                // rights placed
                path: '/groon/connections/index',
                element: <ConnectionsContacts />
            },
            {
                // rights placed
                path: '/groon/connections/contact/:contactId/:tab?',
                element: <ConnectionsCustomer />
            },
            {
                // rights placed
                path: '/groon/connections/jobs',
                element: <ConnectionsTasks />
            },
            {
                // rights placed
                path: '/groon/connections/dashboard',
                element: <ConnectionsIndex />
            },
            {
                // rights placed
                path: '/groon/connections/settings',
                element: <ConnectionsSettings />
            },
        ])
    }

    // Attach Intelligence
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/intelligence',
            element: <IntelligenceIndex />
        }
    ]);

    // Add Omnia Routes
    routesConfig.push({
        path: '/groon',
        element: (
            <AuthGuard>
                <OmniaLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </OmniaLayout>
            </AuthGuard>
        ),
        children: omniaRoutes
    });

    // Include the unsubscribe view of contacts module
    routesConfig.push({
        path: '/crm',
        element: (
            <GuestGuard>
                <AuthLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </AuthLayout>
            </GuestGuard>
        ),
        children: [
            {
                path: '/crm/unsubscribe/:hash',
                element: <ConnectionsOptOut />
            },
            {
                path: '/crm/opt_in/:hash',
                element: <ConnectionsOptIn />
            }
        ]
    })

    // Add all custom routes (concat with the array)
    routesConfig = routesConfig.concat(customRoutes);

    // Include the marketing callbacks
    routesConfig.push({
        path: '/groon/marketing/callbacks/facebook',
        element: <MarketingFacebookCallback />
    });

    // CMS overtake point TODO: also check if CMS is active!!! IMPORTANT!!!
    if(hasCms){
        routesConfig.push({
            path: "/:url?/:identifier?",
            element: <MarketingBuilder />,
        });
    }

    // If nothing else hits
    routesConfig.push({
        path: '*',
        element: <NotFound />
    })

    // Return the final object
    return routesConfig;

}
