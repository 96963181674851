import {useTheme} from "@mui/system";
import {useIsMobile} from "src/omnia/hooks/use-is-mobile";
import React, {useEffect, useState} from "react";
import {Box, Card, CardMedia, Fade, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import Linkify from "react-linkify";
import PerfectScrollbar from "react-perfect-scrollbar";
import PostFileItem from "src/omnia/components/modules/core/connect/post-card/post-file-item";
import moment from "moment/moment";
import {Lightbox} from "react-modal-image";
import PropTypes from "prop-types";
import HtmlWrapper from "src/omnia/components/elements/html-wrapper";
import {APP_SETTING} from "../../../../../../setup";

export const MessageRenderer = (props) => {

    const {
        message,
        assistantName,

        contentType,
        position,
        body,
        isEmoji,
        files,
        onFileUpdate,
        smallVersion,

        lastUserMessage,
        firstUserMessage,
        isShortMessage,
        ...other
    } = props;

    const theme = useTheme();
    const { isMobile } = useIsMobile();
    const [openedFile, setOpenedFile] = useState(null);

    const chatBubble = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: position === 'right' ? 'flex-end' : 'flex-start',
                    maxWidth: '100%'
                }}
                id="ChatBubble"
                {...other}
            >
                <Stack
                    alignItems="flex-start"
                    direction={position === 'right' ? 'row-reverse' : 'row'}
                    spacing={2}
                    sx={{
                        // maxWidth: 500,
                        maxWidth: 'min(100%, 500px)',
                        ml: position === 'right' ? 'auto' : 0,
                        mr: position === 'left' ? 'auto' : 0
                    }}
                >

                    <Box sx={{display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%'}}>
                        {isEmoji ? (
                            <Box
                                style={{
                                    fontSize: smallVersion ? 40 : 50,
                                    textAlign: position
                                }}
                            >
                                {body}
                            </Box>
                        ) : (
                            <Card
                                sx={{
                                    backgroundColor: position === 'right' ? 'primary.main' : 'background.paper',
                                    color: position === 'right' ? 'primary.contrastText' : 'text.primary',
                                    borderRadius: theme?.config?.message_radius + 'px',
                                    maxWidth: '100%'
                                }}
                            >
                                <Box>
                                    {(firstUserMessage && (position === "left")) && (
                                        <Box sx={{
                                            px: 1.75,
                                            py: smallVersion ? 0 : 0.75,
                                            pt: 0.75,
                                        }}>
                                            <Link
                                                underline="hover"
                                                color="inherit"
                                                component={RouterLink}
                                                to={'#'}
                                                sx={{
                                                    cursor: 'pointer',
                                                    fontWeight: 600,
                                                    fontSize: smallVersion ? 12 : 13,
                                                }}
                                            >
                                                {message.sender?.first_name || assistantName}
                                            </Link>
                                        </Box>
                                    )}
                                </Box>
                                {contentType === 'image' && (
                                    <CardMedia
                                        component="img"
                                        onClick={() => setOpenedFile(body)}
                                        image={body}
                                        height="auto"
                                        maxWidth="100%"
                                        sx={{ maxWidth: '100%' }}
                                    />
                                )}
                                {contentType === 'text' && (
                                    <>
                                        <Typography
                                            sx={{
                                                px: 1.5,
                                                py: 0.75
                                            }}
                                            color="inherit"
                                            variant={(smallVersion || isMobile) ? "body2" : "body1"}
                                        >
                                            <Linkify properties={{target: '_blank', style: {color: position === "right" ? theme.palette.primary.contrastText : theme.palette.primary.main}}}>
                                                <HtmlWrapper
                                                    html={body}
                                                    styles={{
                                                        fontSize: (smallVersion || isMobile) ? theme.config?.font_body2_size : theme.config?.font_subtitle1_size
                                                    }}
                                                />
                                            </Linkify>
                                        </Typography>
                                        {files && (files.length > 0) && (
                                            <Box mt={2}>
                                                <PerfectScrollbar>
                                                    <Box style={{maxHeight: 500}}>
                                                        {files.map((file, i) => {
                                                            return (
                                                                <PostFileItem
                                                                    key={message.id + '-file-' + i}
                                                                    simpleIcon={true}
                                                                    file={file}
                                                                    showDivider={i < files.length - 1}
                                                                    deleteCallback={onFileUpdate}
                                                                    onUpdate={onFileUpdate}
                                                                    sx={{color: (position === "right" ? theme.palette.primary.contrastText : theme.palette.text.primary)}}
                                                                />
                                                            )
                                                        })}
                                                    </Box>
                                                </PerfectScrollbar>
                                            </Box>
                                        )}
                                    </>
                                )}

                            </Card>
                        )}
                    </Box>
                </Stack>
            </Box>
            {lastUserMessage && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: position === 'right' ? 'flex-end' : 'flex-start',
                    }}
                    pl={position === 'right' ? 0 : 1}
                    pr={position === 'left' ? 0 : 1}
                >
                    <Typography
                        color="text.secondary"
                        noWrap
                        variant="caption"
                    >
                        {moment(message.sent_at).fromNow()}
                    </Typography>
                </Box>
            )}
            {openedFile && (
                <div style={{zIndex: 1300}}>
                    <Lightbox
                        large={openedFile + '?quality=original'}
                        medium={openedFile + '?quality=highest'}
                        onClose={() => setOpenedFile(null)}
                    />
                </div>
            )}
        </>
    )

    const largeMessage = (
        <Stack direction="column" spacing={1} sx={{maxWidth: '100%'}} id="LargeMessage">
            {firstUserMessage && (
                <Typography
                    color="inherit"
                    variant="h6"
                >
                    {message.sender?.first_name || assistantName}
                </Typography>
            )}
            <Typography
                color="inherit"
                variant="subtitle1"
            >
                <Linkify properties={{target: '_blank', style: {color: position === "right" ? theme.palette.primary.contrastText : theme.palette.primary.main}}}>
                    <HtmlWrapper html={body} />
                </Linkify>
            </Typography>
            {files && (files.length > 0) && (
                <Box>
                    <PerfectScrollbar>
                        <Box style={{maxHeight: 500}}>
                            {files.map((file, i) => {
                                return (
                                    <PostFileItem
                                        key={message.id + '-file-' + i}
                                        simpleIcon={true}
                                        file={file}
                                        showDivider={i < files.length - 1}
                                        deleteCallback={onFileUpdate}
                                        onUpdate={onFileUpdate}
                                        sx={{color: (position === "right" ? theme.palette.primary.contrastText : theme.palette.text.primary)}}
                                    />
                                )
                            })}
                        </Box>
                    </PerfectScrollbar>
                </Box>
            )}
            {lastUserMessage && (
                <Box
                    pl={0}
                    pr={0}
                >
                    <Typography
                        color="text.secondary"
                        noWrap
                        variant="caption"
                    >
                        {moment(message.sent_at).fromNow()}
                    </Typography>
                </Box>
            )}
        </Stack>
    )

    if(!body)
        return null;

    return (
        <Box sx={firstUserMessage ? {mt: smallVersion ? 2 : 4, maxWidth: '100%'} : {maxWidth: '100%', mt: smallVersion ? 0.1 : 1}} id="MessageRendererComponent">
            {smallVersion ? chatBubble : (
                <>
                    {isShortMessage ? chatBubble : largeMessage}
                </>
            )}
        </Box>
    )

};

const Message = ({message, smallVersion, assistantName, index, initial, lastUserMessage, firstUserMessage}) => {

    const [show, setShow] = useState(false);
    const [files, setFiles] = useState([]);
    const [featureImages, setFeatureImages] = useState([]);
    const [isEmoji, setIsEmoji] = useState(false);

    const updateFiles = () => {
        setFeatureImages([]);

        // TODO: update this accordingly
        // get('messages/' + message.id + '/files').then(messageFiles => {
        //     let fileList = [];
        //     let feature = null;
        //     if (messageFiles.length > 0) {
        //         for (let i = 0; i < messageFiles.length; i++) {
        //             if (imageTypes.includes(messageFiles[i].type.toLowerCase())) {
        //                 feature = getSrc(messageFiles[i].view);
        //                 setFeatureImages(prev => prev.concat([feature]));
        //             } else {
        //                 fileList.push(messageFiles[i]);
        //             }
        //         }
        //     }
        //     if (fileList.length > 0) {
        //         setFiles(fileList);
        //     }
        // });
    }

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, initial ? (index * 25) : 0);
    }, [index, initial]);

    useEffect(() => {
        if ((message.files.length > 0)) {
            updateFiles();
        } else {
            setFiles([]);
            setFeatureImages([]);
        }
        // check if message is emoji
        setIsEmoji(/\p{Extended_Pictographic}/u.test(message.message) && !/[a-zA-Z]/.test(message.message));
    }, [message]);

    return (
        <Fade in={show} timeout={APP_SETTING?.transitionDuration || 500}>
            <Box sx={{maxWidth: '100%'}} id="MessageComponent">
                {featureImages.map((feature, index) => (
                    <MessageRenderer
                        message={message}
                        assistantName={assistantName}
                        key={message.id + '-' + index}

                        contentType='image'
                        position={message.my_message ? 'right' : 'left'}
                        isEmoji={isEmoji}
                        body={feature}
                        lastUserMessage={false}
                        firstUserMessage={false}

                        isShortMessage={false}
                        smallVersion={smallVersion}
                    />
                ))}
                <MessageRenderer
                    message={message}
                    assistantName={assistantName}
                    key={message.id}

                    contentType='text'
                    files={files}
                    onFileUpdate={updateFiles}
                    position={message.sender === null ? 'left' : 'right'}
                    isEmoji={isEmoji}
                    body={message.message}

                    lastUserMessage={lastUserMessage}
                    firstUserMessage={firstUserMessage}
                    isShortMessage={message?.message?.length < 400}

                    smallVersion={smallVersion}
                />
            </Box>
        </Fade>
    )
};

Message.propTypes = {
    message: PropTypes.object.isRequired,
    smallVersion: PropTypes.bool.isRequired,
    assistantName: PropTypes.string.isRequired,
    lastUserMessage: PropTypes.bool,
    firstUserMessage: PropTypes.bool
}

MessageRenderer.propTypes = {
    message: PropTypes.object.isRequired,
    contentType: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    isEmoji: PropTypes.bool.isRequired,
    assistantName: PropTypes.string,
    files: PropTypes.array,
    onFileUpdate: PropTypes.func,
    smallVersion: PropTypes.bool,
    lastUserMessage: PropTypes.bool,
    firstUserMessage: PropTypes.bool
}

export default Message;