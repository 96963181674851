import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, CircularProgress, Divider, Stack} from '@mui/material';
import {ChatMessageAdd} from './chat-message-add';
import {ChatMessages} from './chat-messages';
import ChatThreadToolbar from './chat-thread-toolbar';
import {useDispatch, useSelector} from "react-redux";
import {Scrollbar} from "../../../../elements/scrollbar";
import useSocket from "src/omnia/hooks/use-socket";
import produce from "immer";
import {addLatestMessage, markThreadAsSeen} from "../../../../../store/actions/messages-actions";
import useOmniaApi from "../../../../../hooks/use-omnia-api";
import {useTranslation} from "react-i18next";

const useParticipants = (thread) => {

    const user = useSelector(state => state.account.user);
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        if (thread) {
            setParticipants(thread.users.filter(u => u.id !== user.id));
        } else {
            setParticipants([]);
        }
    }, [thread, user]);

    return participants;
};

const useThread = (threadKey) => {

    const [thread, setThread] = useState(null);

    const threads = useSelector(state => state.messages.threads);

    useEffect(() => {
        if (typeof threadKey === "undefined"){
            setThread(null);
        } else {
            if(threads.filter(thread => thread.id === parseInt(threadKey)).length > 0){
                setThread(threads.find(thread => thread.id === parseInt(threadKey)));
            } else {
                setThread(null);
            }
        }
    }, [threads, threadKey]);

    return thread;
};

export const ChatThread = ({threadKey, noToolbar, smallVersion, toggleSidebar,  ...other}) => {

    const { t } = useTranslation();
    const thread = useThread(threadKey);
    const participants = useParticipants(thread);
    const messagesRef = useRef(null);
    const loadMoreRef = useRef(null);
    const users = useSelector(state => state.users.users);
    const dispatch = useDispatch();
    const [currentlyTyping, setCurrentlyTyping] = useState([]);
    const [chatDisabled, setChatDisabled] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isExploring, setIsExploring] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [ sending, setSending ] = useState(true);
    const { uploadStatus, put } = useOmniaApi({autoError: false});
    const { sendMessage, isConnected } = useSocket('thread/' + threadKey, (message) => {

        if(message['type'] === 'noMore'){
            setHasMore(false);
        }

        if(message['type'] === 'hasMore'){
            setHasMore(true);
        }

        if(message['type'] === 'messages'){
            handleNewMessages(message['messages'], message['isInitial']);
            if(message['isInitial']){
                setIsExploring(false);
            }
        }

        if(message['type'] === 'delete'){
            const messageId = message['messageId']
            setMessages(prev => produce(prev, draft => {
                draft = prev.filter(m => m.id !== messageId);
                return draft;
            }));
        }

        if(message['type'] === 'chatDisabled'){
            setChatDisabled(message['data']);
        }

        if(message['type'] === 'message'){
            dispatch(addLatestMessage(message['message'].thread, message['message']));
            handleNewMessages([message['message']], false);
        }

        if(message['type'] === 'typing'){
            if(users.filter(u => u.id === parseInt(message['data'])).length > 0){
                setCurrentlyTyping(prev => produce(prev, draft => {
                    if(!prev.map(u => u.id).includes(parseInt(message['data']))){
                        draft.push(users.find(u => u.id === parseInt(message['data'])));
                    }
                    return draft;
                }));
            }
        }

        if(message['type'] === 'stoppedTyping'){
            if(users.filter(u => u.id === parseInt(message['data'])).length > 0){
                setCurrentlyTyping(prev => produce(prev, draft => {
                    if(prev.map(u => u.id).includes(parseInt(message['data']))){
                        draft = prev.filter(u => u.id !== parseInt(message['data']));
                    }
                    return draft;
                }));
            }
        }

    });
    const exploringRef = useRef();
    exploringRef.current = isExploring;

    const handleUpdate = () => {
        if(exploringRef.current){
            if(loadMoreRef.current){
                loadMoreRef.current.scrollIntoView({ behavior: 'instant' });
            }
        } else {
            if (messagesRef.current) {
                messagesRef.current.scrollIntoView({ behavior: 'instant' });
            }
        }
    }

    const handleNewMessages = (messages, reset=false) => {
        if(reset){
            setMessages(prev => produce(prev, draft => {
                draft = messages.sort((a, b) => (new Date(b.created_at)).getTime() < (new Date(a.created_at)).getTime() ? 1 : -1);
                return draft;
            }));
        } else {
            setMessages(prev => produce(prev, draft => {
                draft = prev.concat(messages.filter(ms => !prev.map(m => m.id).includes(ms.id))).sort((a, b) => (new Date(b.created_at)).getTime() < (new Date(a.created_at)).getTime() ? 1 : -1);
                return draft;
            }));
        }
    }

    const handleSend = (values) => {
        return new Promise((resolve, reject) => {
            setSending(true);
            put('threads/' + thread.id + '/messages', values).then((message) => {
                setIsExploring(false);
                handleNewMessages([message]);
                resolve(message);
            }).catch(errors => {
                reject(errors);
            }).finally(() => {
                setSending(false);
            })
        });
    }

    const handleTyping = () => {
        sendMessage({type: 'typing'});
    }

    const handleStoppedTyping = () => {
        sendMessage({type: 'stoppedTyping'});
    }

    const handleLoadedContent = () => {
        handleUpdate();
        setTimeout(() => {
            handleUpdate();
        }, 100);
        setTimeout(() => {
            handleUpdate();
        }, 500);
    }

    const handleLoadMore = () => {
        setIsExploring(true);
        sendMessage({
            type: 'loadMore'
        })
    }

    useEffect(() => {
        handleUpdate();
    }, [currentlyTyping]);

    useEffect(() => {
        handleUpdate();
        setTimeout(() => {
            handleUpdate();
        }, 500);
    }, [messages]);

    useEffect(() => {
        if(isConnected && thread){
            dispatch(markThreadAsSeen(thread.id));
            setIsExploring(false);
        } else {
            setMessages([]);
            setCurrentlyTyping([]);
        }
    }, [isConnected, thread]);

    if(!thread)
        return null;

    return (
        <Stack
            sx={{
                flexGrow: 1,
                overflow: 'hidden',
            }}
            {...other}
        >
            {!noToolbar && (
                <>
                    <ChatThreadToolbar
                        participants={participants}
                        thread={thread}
                        toggleSidebar={toggleSidebar}
                    />
                    <Divider/>
                </>
            )}
            <Box
                sx={{
                    flexGrow: 1,
                    overflow: 'hidden'
                }}
            >
                {!isConnected ? (
                    <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress/>
                    </div>
                ) : (
                    <Scrollbar
                        id="MessagesScrollbar"
                        sx={{height: '100%', maxWidth: '100%'}}
                    >
                        {hasMore && (
                            <Stack direction="row" justifyContent="center" alignItems="center" sx={{height: 50}}>
                                <Button onClick={handleLoadMore} color="primary" ref={loadMoreRef}>
                                    {t('common.load_more')}
                                </Button>
                            </Stack>
                        )}
                        <ChatMessages
                            onLoadedContent={handleLoadedContent}
                            messages={messages || []}
                            thread={thread}
                            typing={currentlyTyping}
                            smallVersion={smallVersion}
                        />
                        <div ref={messagesRef}/>
                    </Scrollbar>
                )}
            </Box>
            <ChatMessageAdd
                size="small"
                uploadStatus={uploadStatus}
                sending={sending}
                smallVersion={smallVersion}
                disabled={chatDisabled}
                onSend={handleSend}
                onTyping={handleTyping}
                onStopTying={handleStoppedTyping}
            />
        </Stack>
    );
};

ChatThread.propTypes = {
    threadKey: PropTypes.string.isRequired,
    smallVersion: PropTypes.bool,
    toggleSidebar: PropTypes.func,
    noToolbar: PropTypes.bool,
};

ChatThread.defaultProps = {
    noToolbar: false,
    smallVersion: false,
}