import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography} from "@mui/material";
import useApi from "src/omnia/hooks/use-api";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

function FileRenameDialog({file, open, handleClose, onSuccess}) {

    const [newName, setNewName] = useState('');
    const {put} = useApi();
    const {t} = useTranslation();

    const renameFile = () => {
        put('files', {...file, ...{name: newName}}).then((updated) => {
            if (onSuccess)
                onSuccess(updated);
            handleClose();
        });
    }

    useEffect(() => {
        setNewName(file.name);
    }, [file]);

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogContent>
                <Typography variant="h4" color="textPrimary" sx={{mb: 4}}>
                    {t("Edit")}
                </Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    onChange={(event) => {
                        setNewName(event.target.value);
                    }}
                    id="name"
                    value={newName}
                    label={t("File Name")}
                    type="text"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    {t("Cancel")}
                </Button>
                <Button onClick={renameFile} variant="contained" color="primary">
                    {t("Rename")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

FileRenameDialog.propTypes = {
    file: PropTypes.object,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    onSuccess: PropTypes.func
}

export default FileRenameDialog;