import PropTypes from 'prop-types';
import {Box, Divider, Link, Stack} from '@mui/material';
import {Link as RouterLink} from "react-router-dom";
import Logo from "../../../general/logo";
import React, {useEffect, useState} from "react";
import {useSettings} from "src/omnia/hooks/use-settings";

export const TenantSwitch = (props) => {

    const settings = useSettings();
    const [logoColor, setLogoColor] = useState("light");

    useEffect(() => {
        if (settings.paletteMode === "light") {
            if (settings.navColor === "evident") {
                setLogoColor('light');
            } else {
                setLogoColor('dark');
            }
        } else {
            setLogoColor('light');
        }
    }, [settings]);

    return (
        <>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}
                {...props}
            >
                <Link
                    href="/"
                    component={RouterLink}
                >
                    <Box
                        style={{
                            width: '100%',
                            height: '100%',
                            padding: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >

                        <Logo
                            isHeader
                            width={settings?.layout === 'horizontal' ? 'auto' : '70%'}
                            height={settings?.layout === 'horizontal' ? 35 : 'auto'}
                            color={logoColor}
                        />
                    </Box>
                </Link>
                {/*<Divider*/}
                {/*    sx={{*/}
                {/*        backgroundColor: '#2D3748',*/}
                {/*        borderColor: '#2D3748',*/}
                {/*    }}*/}
                {/*/>*/}
            </Stack>
        </>
    );
};

TenantSwitch.propTypes = {
    sx: PropTypes.object
};
