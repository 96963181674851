import PropTypes from 'prop-types';
import {useMediaQuery} from '@mui/material';
import {styled} from '@mui/material/styles';
import {MobileNav} from '../mobile-nav';
import {SideNav} from './side-nav';
import {TopNav} from './top-nav';
import {useMobileNav} from './use-mobile-nav';
import ErrorHandler from "../../../elements/error-handler";

const SIDE_NAV_WIDTH = 250;

const VerticalLayoutRoot = styled('div')(({theme, mobileNavActive}) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingLeft: mobileNavActive ? 0 : SIDE_NAV_WIDTH
}));

const VerticalLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%'
});

export const VerticalLayout = (props) => {
    const {children, sections, navColor, navMode} = props;
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const mobileNav = useMobileNav();
    const mobileNavActive = !lgUp || (navMode === 'hidden');

    return (
        <>
            <TopNav
                onMobileNavOpen={mobileNav.handleOpen}
                mobileNavActive={mobileNavActive}
            />
            {!mobileNavActive && (
                <SideNav
                    color={navColor}
                    sections={sections}
                />
            )}
            {mobileNavActive && (
                <MobileNav
                    color={navColor}
                    onClose={mobileNav.handleClose}
                    open={mobileNav.open}
                    sections={sections}
                />
            )}
            <VerticalLayoutRoot mobileNavActive={mobileNavActive}>
                <VerticalLayoutContainer>
                    <ErrorHandler>
                        {children}
                    </ErrorHandler>
                </VerticalLayoutContainer>
            </VerticalLayoutRoot>
        </>
    );
};

VerticalLayout.propTypes = {
    children: PropTypes.node,
    navColor: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
    navMode: PropTypes.oneOf(['normal', 'hidden']),
    sections: PropTypes.array,
};
