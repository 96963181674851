/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import produce from 'immer';
import _ from "lodash";
import {
    REMOVE_VISITOR,
    SET_UNREAD,
    UPDATE_ACTIVITY,
    UPDATE_VISITOR,
    UPDATE_VISITORS,
    SET_NUMBER_ONLINE,
    SET_NUMBER_VISITORS,
} from 'src/omnia/store/actions/visitors-actions';

const initialState = {
    visitors: [],
    numberOnline: 0,
    numberRequests: 0,
    numberVisitors: 0,
    initialized: false,
    unread: 0,
};

const websiteReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_NUMBER_ONLINE: {
            const { numberOnline } = action.payload;
            return produce(state, draft => {
                draft.numberOnline = numberOnline;
            })
        }

        case SET_NUMBER_VISITORS: {
            const { numberVisitors } = action.payload;
            return produce(state, draft => {
                draft.numberVisitors = numberVisitors;
            })
        }

        case UPDATE_VISITORS: {
            const { data } = action.payload;
            return produce(state, draft => {
                draft.visitors = data;
                draft.initialized = true;
            })
        }

        case UPDATE_ACTIVITY: {
            const { vId, url, scrollPos } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.visitors, {'id': vId});
                if(index !== -1){
                    draft.visitors[index]['current_url'] = url + ' (' + scrollPos + '%)'
                    draft.visitors[index]['total_interactions'] += 1
                }
            })

        }

        case UPDATE_VISITOR: {
            const { visitor } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.visitors, {'id': visitor.id});
                if(index !== -1){
                    draft.visitors[index] = _.merge(draft.visitors[index], visitor);
                } else {
                    draft.visitors = state.visitors.concat([visitor]);
                }
            });
        }

        case REMOVE_VISITOR: {
            const { visitorId } = action.payload;
            return produce(state, draft => {
                draft.visitors = state.visitors.filter(v => v.id !== visitorId);
            });
        }

        case SET_UNREAD: {
            const { unread } = action.payload;
            return produce(state, (draft) => {
                draft.unread = unread;
            });
        }
        default: {
            return state;
        }
    }
}

export default websiteReducer;