/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import {
    SAVE_STATE, LOAD_SETTING, UPDATE_DATE_UPDATE
} from 'src/omnia/store/actions/datatable-actions';
import produce from "immer";
import _ from "lodash";

const initialState = {
    tableStates: [],
    synchronized: false,
    updates: {}
};

const datatableReducer = (state = initialState, action) => {

    switch (action.type) {

        case UPDATE_DATE_UPDATE: {
            const { endpoint } = action.payload;
            return produce(state, (draft) => {
                draft.updates[endpoint] = (new Date()).getTime();
            });
        }

        case LOAD_SETTING: {
            const { settings } = action.payload;
            return produce(state, (draft) => {
                if(typeof settings === "object")
                    draft.tableStates = settings;
                draft.synchronized = true;
            })
        }

        case SAVE_STATE: {
            const {ident, gridState} = action.payload;
            return produce(state, (draft) => {
                let index = _.findIndex(state.tableStates, {'id': ident});

                // Try to build the table state
                try {
                    if(index !== -1){
                        draft.tableStates[index]['state'] = gridState;
                    } else {
                        draft.tableStates = state.tableStates.concat([{
                            'id': ident,
                            'state': gridState,
                            // TODO: add also a list of columns (in case the model changes)?
                        }]);
                    }
                } catch (e) {
                    // If that fails, build empty list (reset-password table states)
                    console.log('Resetted datatable states');
                    draft.tableStates = [];
                }

            });
        }

        default: {
            return state;
        }
    }
};

export default datatableReducer;
