import React from "react";
import {Button, SvgIcon} from "@mui/material";
import {useTranslation} from "react-i18next";
import Save from "@untitled-ui/icons-react/build/esm/Save01";
import {LoadingButton} from "@mui/lab";

export default function SaveButton({loading, label, noIcon, ...props}){

    const { t } = useTranslation();

    return (
        <LoadingButton
            // startIcon={!noIcon && (
            //     <SvgIcon fontSize="small">
            //         <Save />
            //     </SvgIcon>
            // )}
            variant="contained"
            loading={loading}
            {...props}
        >
            {label ? label : t("common.save")}
        </LoadingButton>
    )
}

SaveButton.defaultProps = {
    loading: false,
    noIcon:  false,
    label: null
}