import SettingsIcon from '@untitled-ui/icons-react/build/esm/Palette';
import {IconButton, SvgIcon, Tooltip} from '@mui/material';
import {useSettings} from "src/omnia/hooks/use-settings";
import {useTranslation} from "react-i18next";

export const SettingsButton = () => {

    const settings = useSettings();
    const { t } = useTranslation();

    const handleOpen = () => {
        settings.handleDrawerOpen();
    };

    return (
        <>
            <Tooltip title={t("common.on_settings.title")}>
                <IconButton onClick={handleOpen}>
                    <SvgIcon>
                        <SettingsIcon/>
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        </>
    );
};
