export const UPDATE_SITES = '@marketing-system/update-sites';
export const UPDATE_LANGUAGES = '@marketing-system/update-languages';
export const UPDATE_LANGUAGE = '@marketing-system/update-language';
export const REMOVE_LANGUAGE = '@marketing-system/remove-language';
export const REMOVE_TEXTS = '@marketing-system/remove-texts';
export const UPDATE_TEXTS = '@marketing-system/update-texts';
export const UPDATE_TEXT = '@marketing-system/update-text';
export const UPDATE_SITE = '@marketing-system/update-site';
export const UPDATE_ELEMENT = '@marketing-system/update-element';
export const REMOVE_ELEMENT = '@marketing-system/remove-element';
export const UPDATE_SITE_URL = '@marketing-system/update-site-url';
export const REMOVE_SITE = '@marketing-system/remove-site';
export const REMOVE_TEXT = '@marketing-system/remove-single-text';
export const UPDATE_VISITORS = '@marketing-system/update-visitors';
export const UPDATE_VISITOR = '@marketing-system/update-visitor';
export const REMOVE_VISITOR = '@marketing-system/remove-visitor';
export const UPDATE_VISITOR_ACTIVITY = '@marketing-system/remove-visitor-activity';
export const UPDATE_VISITOR_VISIBILITY = '@marketing-system/remove-visitor-visibility';

export function updateVisitorVisibility(visitor_id, status){
    return dispatch => dispatch({
        type: UPDATE_VISITOR_VISIBILITY,
        payload: {
            visitor_id, status
        }
    })
}

export function updateVisitorActivity(activity, visitorId){
    return dispatch => dispatch({
        type: UPDATE_VISITOR_ACTIVITY,
        payload: {
            activity,
            visitorId
        }
    })
}

export function updateVisitors(data) {
    return (dispatch) => dispatch({
        type: UPDATE_VISITORS,
        payload: {
            data
        }
    });
}

export function updateVisitor(visitor) {
    return (dispatch) => dispatch({
        type: UPDATE_VISITOR,
        payload: {
            visitor
        }
    })
}

export function removeVisitor(visitorId) {
    return (dispatch) => dispatch({
        type: REMOVE_VISITOR,
        payload: {
            visitorId
        }
    })
}

export function updateSiteUrl(url){
    return dispatch => dispatch({
        type: UPDATE_SITE_URL,
        payload: {
            url
        }
    })
}

export function updateLanguage(language){
    return dispatch => dispatch({
        type: UPDATE_LANGUAGE,
        payload: {
            language
        }
    })
}

export function removeLanguage(lan){
    return dispatch => dispatch({
        type: REMOVE_LANGUAGE,
        payload: {
            lan
        }
    })
}

export function updateSites(data) {
    return (dispatch) => dispatch({
        type: UPDATE_SITES,
        payload: {
            data
        },
    });
}

export function removeSingleText(textId){
    return dispatch => dispatch({
        type: REMOVE_TEXT,
        payload: {
            textId
        }
    })
}

export function removeText(elementId){
    return dispatch => dispatch({
        type: REMOVE_TEXTS,
        payload: {
            elementId
        }
    })
}

export function updateLanguages(languages){
    return (dispatch) => dispatch({
        type: UPDATE_LANGUAGES,
        payload: {
            languages
        }
    })
}

export function updateTexts(texts){
    return (dispatch) => dispatch({
        type: UPDATE_TEXTS,
        payload: {
            texts
        }
    })
}

export function updateText(text){
    return (dispatch) => dispatch({
        type: UPDATE_TEXT,
        payload: {
            text
        }
    })
}

export function updateSite(site) {
    return (dispatch) => dispatch({
        type: UPDATE_SITE,
        payload: {
            site
        }
    })
}

export function updateSiteElement(element) {
    return (dispatch) => dispatch({
        type: UPDATE_ELEMENT,
        payload: {
            element
        }
    })
}

export function removeSite(siteId) {
    return (dispatch) => dispatch({
        type: REMOVE_SITE,
        payload: {
            siteId
        }
    })
}

export function removeSiteElement(remElement) {
    return (dispatch) => dispatch({
        type: REMOVE_ELEMENT,
        payload: {
            remElement
        }
    })
}