import PropTypes from 'prop-types';
import LeftIndent01Icon from '@untitled-ui/icons-react/build/esm/LeftIndent01';
import RightIndent01Icon from '@untitled-ui/icons-react/build/esm/RightIndent01';
import {Chip, Stack, SvgIcon, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";


export const OptionsDirection = (props) => {
    const {onChange, value} = props;
    const { t } = useTranslation();

    const options = [
        {
            label: t('common.on_settings.left_to_right'),
            value: 'ltr',
            icon: (
                <SvgIcon fontSize="small">
                    <LeftIndent01Icon/>
                </SvgIcon>
            )
        },
        {
            label: t('common.on_settings.right_to_left'),
            value: 'rtl',
            icon: (
                <SvgIcon fontSize="small">
                    <RightIndent01Icon/>
                </SvgIcon>
            )
        }
    ];

    return (
        <Stack spacing={1}>
            <Typography
                color="text.secondary"
                variant="overline"
            >
                {t("common.on_settings.orientation")}
            </Typography>
            <Stack
                alignItems="center"
                direction="row"
                flexWrap="wrap"
                gap={2}
            >
                {options.map((option) => (
                    <Chip
                        icon={option.icon}
                        key={t(option.label)}
                        label={option.label}
                        onClick={() => onChange?.(option.value)}
                        sx={{
                            borderColor: 'transparent',
                            borderRadius: 1.5,
                            borderStyle: 'solid',
                            borderWidth: 2,
                            ...(option.value === value && {
                                borderColor: 'primary.main'
                            })
                        }}
                    />
                ))}
            </Stack>
        </Stack>
    );
};

OptionsDirection.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOf(['ltr', 'rtl'])
};
